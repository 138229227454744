import { Button, Checkbox, Table, TableCell, TableRow, Text } from '@aurecon-creative-technologies/styleguide';
import { observer } from 'mobx-react-lite';
import React, { FC, useEffect, useState } from 'react';
import { IContainerFile, IFileContainer } from '../../../api/authenticated/cms/FileContainerModel';
import { FromModuleEnum } from '../../../enums/FromModuleEnum';
import AppStore from '../../../stores/AppStore';
import { classNames, getFileSizeDetails, getFileSizeString } from '../../../utils/miscUtils';
import { ContentSelection } from '../../files/ContentSelection';
import FileInformation from '../../files/fileInformation/FileInformation';
import FileInformationStore from '../../files/fileInformation/FileInformationStore';
import FilesStore from '../../files/FilesStore';
import CentreOnPage from '../../shared/CentreOnPage';
import CloseButton from '../../shared/CloseButton';
import GoBackButton from '../../shared/GoBackButton';
import Icon from '../../shared/Icon';
import Modal from '../../shared/Modal';
import ModalActions from '../../shared/ModalActions';
import Overlay from '../../shared/Overlay';
import PrimaryButton from '../../shared/PrimaryButton';
import SecondaryButton from '../../shared/SecondaryButton';
import AddTransmittalMessageStore from '../../transmittalDetails/AddTransmittalMessage/AddTransmittalMessageStore';
import TransmittalDetailsFilesStore from '../../transmittalDetails/TransmittalDetailsFilesStore';
import CreateTransmittalStore from '../CreateTransmittalStore';
import Style from './styles/ContentFilesUploadModal.module.scss';
import TransmittalContentFileAction from './TransmittalContentFileAction';

export interface IContentFileUploadProps {
  isRespond: boolean;
  selectedContentContainers: IFileContainer[];
  onAddContentFilesToTransmittal: (containerFileIds: number[]) => void;
  onCancelAddSelectedContentFiles: () => void;
}

const ContentFilesUploadModal: FC<IContentFileUploadProps> = ({
  isRespond,
  selectedContentContainers,
  onAddContentFilesToTransmittal,
  onCancelAddSelectedContentFiles,
}) => {
  const [selectedTransmittalContentFiles, setSelectedTransmittalContentFiles] = useState(selectedContentContainers);
  const [containerFiles, setContainerFiles] = useState<IContainerFile[]>([]);
  const [selectedFileIds, setSelectedFileIds] = useState<number[]>([]);

  useEffect(() => {
    setSelectedTransmittalContentFiles(selectedContentContainers);
    const selectedContainerFiles = selectedContentContainers.flatMap((x) => x.containerFiles!);
    setContainerFiles(selectedContainerFiles);
    const ContainerFileIds = selectedContainerFiles.map((x) => x.containerFileId!);
    setSelectedFileIds(ContainerFileIds);
  }, [selectedContentContainers]);
  if (!selectedContentContainers.length) return null;
  const handleFileSelected = (fileIds: number[], checked: boolean, checkedContainer: boolean) => {
    let newSelectedFileIds = [...selectedFileIds];
    if (checkedContainer) {
      const selectedFileContainer = selectedTransmittalContentFiles.filter((file) => fileIds.includes(file.id));
      const selectedContainerFiles = selectedFileContainer.flatMap((x) =>
        x.containerFiles!.map((x) => x.containerFileId!)
      );
      fileIds = selectedContainerFiles;
    }
    fileIds.forEach((id) => {
      const idx = newSelectedFileIds.findIndex((f) => f === id);
      if (!checked && idx > -1) {
        newSelectedFileIds.splice(idx, 1);
      }
      const selectedFileContainer = containerFiles
        .filter((x) => x.containerFileId === id)
        .map((x) => x.fileContainerId!)[0];
      getContainerFiles(selectedFileContainer);
      newSelectedFileIds = idx > -1 ? newSelectedFileIds : [...newSelectedFileIds, id];
    });
    setSelectedFileIds(newSelectedFileIds);
  };

  const onUploadButtonClick = () => {
    onAddContentFilesToTransmittal?.(selectedFileIds);
  };
  const handleCancelButtonClick = () => {
    onCancelAddSelectedContentFiles?.();
  };

  const handleShowFileButtonClick = async () => {
    CreateTransmittalStore.toggleShowSelectedContentFiles();
    if (CreateTransmittalStore.showSelectedContentFiles) {
      const fileContainerIds = containerFiles
        .filter((x) => selectedFileIds.includes(x.containerFileId ?? 0))
        .map((x) => x.fileContainerId!);
      const filterSelectedFiles = selectedTransmittalContentFiles
        .filter((file) => fileContainerIds.includes(file.id))
        .map((m) => {
          return {
            ...m,
            containerFiles: m.containerFiles!.filter((f) => selectedFileIds.includes(f.containerFileId ?? 0)),
          };
        });
      setSelectedTransmittalContentFiles(filterSelectedFiles);
      return;
    }
    setSelectedTransmittalContentFiles(selectedContentContainers);
  };

  const getContainerFiles = (containerId: number) => {
    const selectedContainerFiles = containerFiles
      .filter((x) => x.fileContainerId === containerId)
      .map((x) => x.containerFileId);
    const fileContainer = selectedContentContainers
      .filter((x) => x.id === containerId)
      .flatMap((x) => x.containerFiles!);
    const selectedFiles = selectedFileIds.filter((t) => selectedContainerFiles.includes(t));
    return fileContainer.length === selectedFiles.length;
  };
  return (
    <CentreOnPage>
      <Overlay />
      <Modal className={Style.container}>
        <CloseButton onClick={handleCancelButtonClick} disabled={false} />
        <div>
          <GoBackButton
            className={Style.goBackBtn}
            onClick={() => {
              if (isRespond) {
                AddTransmittalMessageStore.setIsOpenFiles(true);
              } else {
                CreateTransmittalStore.setIsOpenFiles(true);
              }
              AppStore.setDisableNavigation(true);
              FilesStore.cleanup();
              FilesStore.applyFileFilterForTransmittals(
                ContentSelection.Shared,
                AppStore.selectedProjectNumber,
                AppStore.selectedTaskTeamId
              );
              FilesStore.setSelectedSection(ContentSelection.Shared);
              FilesStore.setFromModule(FromModuleEnum.TRANSMITTALS);
            }}
          />
          <div className={Style.heading}>
            <Text type="h3">Add Content File(s)</Text>
          </div>
          <div className={Style.contentFiles}>
            <div className={Style.contentFilesHeading}>
              <span className={Style.label}>
                <div className={Style.totalSelected}>{selectedFileIds.length} selected</div>
                {!!selectedFileIds.length && (
                  <Button
                    type="text"
                    cssClass={Style.linkLabel}
                    onClick={handleShowFileButtonClick}
                    label={!CreateTransmittalStore.showSelectedContentFiles ? 'Show Selected' : 'Show All'}
                  />
                )}
              </span>
            </div>
            <div
              className={classNames(Style.tableContainer, [
                FilesStore.isShowingFileInformation,
                Style.showFileInformation,
              ])}>
              <Table
                hoverable
                cssClass={Style.contentFileTable}
                headers={[
                  {
                    label: '',
                    onCheckbox: (checked) =>
                      handleFileSelected(
                        selectedContentContainers.map((f) => f.id),
                        checked,
                        true
                      ),
                    checked: containerFiles.length === selectedFileIds.length,
                  },
                  {
                    label: 'Filename',
                  },
                  {
                    label: 'Original Filename',
                  },
                  {
                    label: 'File Size',
                  },
                  {
                    label: 'Actions',
                  },
                ]}>
                {selectedTransmittalContentFiles.map((fileContainer) => {
                  const file = fileContainer.containerFiles?.some((tf) => tf.native)
                    ? fileContainer.containerFiles?.find((tf) => tf.native)
                    : (fileContainer.containerFiles || [])[0];
                  const checked = getContainerFiles(fileContainer.id);
                  return (
                    <>
                      <TableRow
                        key={FilesStore.getFileKey(fileContainer)}
                        onClick={() => {
                          if (FileInformationStore.file?.id === fileContainer.id) {
                            FileInformationStore.close();
                            return;
                          }
                          TransmittalDetailsFilesStore.showFileInformation(fileContainer);
                        }}>
                        <TableCell cellClass={Style.checkbox}>
                          <button
                            onClick={(event) => {
                              event.preventDefault();
                              event.stopPropagation();
                              handleFileSelected([fileContainer.id], !checked, true);
                            }}>
                            <Checkbox checked={checked} />
                          </button>
                        </TableCell>
                        <TableCell>{fileContainer.title}</TableCell>
                        <TableCell>{file?.originalFilename ?? fileContainer.originalFilename}</TableCell>
                        <TableCell></TableCell>
                        <TableCell>
                          <TransmittalContentFileAction
                            file={{
                              ...fileContainer,
                              containerFileId: file?.containerFileId ?? fileContainer.containerFileId,
                              originalFilename: file?.originalFilename ?? fileContainer.originalFilename,
                              extension: file?.fileExtension ?? fileContainer.extension,
                              isForgeFile:
                                file?.isForgeFile !== null && file?.isForgeFile !== undefined
                                  ? file.isForgeFile
                                  : fileContainer.isForgeFile,
                              hasDocumentViewer:
                                file?.hasDocumentViewer !== null && file?.hasDocumentViewer !== undefined
                                  ? file.hasDocumentViewer
                                  : fileContainer.hasDocumentViewer,
                              sharePointReleasedFileId:
                                file?.sharePointReleasedFileId ?? fileContainer.sharePointReleasedFileId,
                            }}
                            showInfo
                            containerFile={null}
                            fileRevisionId={fileContainer.fileRevisionId}
                            releasedFileId={fileContainer.releasedFileId}
                          />
                        </TableCell>
                      </TableRow>
                      {!!fileContainer.containerFiles &&
                        fileContainer.containerFiles.map((file) => (
                          <TableRow key={file.containerFileId}>
                            <TableCell>
                              <Checkbox
                                onChange={(checked) =>
                                  handleFileSelected(file.containerFileId ? [file.containerFileId] : [], checked, false)
                                }
                                checked={selectedFileIds.some((fileId) => fileId === file.containerFileId)}
                              />
                            </TableCell>
                            <TableCell></TableCell>
                            <TableCell>
                              <div className={Style.fileCell}>
                                <Icon name="shortcut" className={Style.fileIcon} />
                                {file.originalFilename}
                                {file.native && <Icon name="star_outline" className={Style.native} />}
                              </div>
                            </TableCell>
                            <TableCell>{getFileSizeString(file.uploadedSize)}</TableCell>
                            <TableCell>
                              <TransmittalContentFileAction
                                file={{
                                  ...fileContainer,
                                  containerFileId: file.containerFileId!,
                                  originalFilename: file.originalFilename,
                                  sharePointReleasedFileId: file.sharePointReleasedFileId,
                                  hasDocumentViewer: file.hasDocumentViewer,
                                  isForgeFile: file.isForgeFile,
                                }}
                                showInfo={false}
                                containerFile={null}
                                fileRevisionId={fileContainer.fileRevisionId ?? undefined}
                                releasedFileId={fileContainer.releasedFileId ?? undefined}
                              />
                            </TableCell>
                          </TableRow>
                        ))}
                    </>
                  );
                })}
              </Table>
              {FilesStore.isShowingFileInformation && (
                <div className={Style.fileDetails}>
                  <FileInformation showFiles={false} />
                </div>
              )}
            </div>
            <div className={Style.labelBottom}>
              <span>
                {containerFiles.map((f) => f.containerFileId).length} files, total of{' '}
                {
                  getFileSizeDetails(containerFiles.reduce((fileSize, file) => fileSize + file.uploadedSize, 0))
                    .totalFileSize
                }
              </span>
            </div>
          </div>
        </div>
        <ModalActions>
          <SecondaryButton onClick={handleCancelButtonClick}>Cancel</SecondaryButton>
          <PrimaryButton disabled={!selectedFileIds.length} onClick={() => onUploadButtonClick()}>
            Upload
          </PrimaryButton>
        </ModalActions>
      </Modal>
    </CentreOnPage>
  );
};

export default observer(ContentFilesUploadModal);
