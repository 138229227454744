export enum DateFilterOperator {
  EQUAL_TO = 1,
  LESS_THAN = 2,
  GREATER_THAN = 3,
  BETWEEN = 4,
}

export interface IListFilter {
  fieldValues: string[];
  filter?: string;
}

export interface ITextFilter {
  filter?: string;
}

export interface IDateRangeFilter {
  startDate: Date | null;
  endDate: Date | null;
  operator: DateFilterOperator | null;
}

export interface ITableColumn {
  label: string;
  valueField: string;
  listFilter?: IListFilter;
  textFilter?: ITextFilter;
  dateFilter?: IDateRangeFilter;
  sort?: string;
  style?: React.CSSProperties;
  width?: number;
  minWidth?: number;
  checked?: boolean;
  visible?: boolean;
  onCheckbox?: (checked: boolean) => void;
  index?: number;
}
