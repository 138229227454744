import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Icon from '../../shared/Icon';
import Style from './styles/TaskEventLabel.module.scss';

export interface ITaskEventProps {
  icon: string;
  label: string;
}

const TaskEvent: FC<ITaskEventProps> = ({ icon, label, children }) => {
  return (
    <>
      <div className={Style.container}>
        <Icon className={Style.icon} name={icon} />
        {label}
      </div>
      <div className={Style.childrenContainer}>{children}</div>
    </>
  );
};

export default observer(TaskEvent);
