import React, { FC, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import JoditEditor from 'jodit-pro-react';
import AppStore from '../../stores/AppStore';

const buttons = [
  'paragraph',
  'font',
  'fontsize',
  '|',
  'bold',
  'italic',
  'underline',
  'strikethrough',
  'brush',
  '|',
  'align',
  '|',
  'ul',
  'ol',
  'outdent',
  'indent',
  '|',
  'link',
  'table',
];

const config = {
  readonly: false,
  placeholder: 'Start typings...',
  license: AppStore.client?.joditKey,
  buttons: buttons,
  uploader: {
    insertImageAsBase64URI: true,
  },
  controls: {
    font: {
      list: {
        'Arial, Helvetica, sans-serif': 'Arial',
        'Calibri, sans-serif': 'Calibri',
        'Candara, sans-serif': 'Candara',
        'Century Gothic, sans-serif': 'Century Gothic',
        "'Comic Sans MS', sans-serif": 'Comic Sans',
        "'Franklin Gothic', 'Arial Narrow', Arial, sans-serif": 'Franklin Gothic',
        'Georgia, Palatino, serif': 'Georgia',
        'Impact, Charcoal, sans-serif': 'Impact',
        'Tahoma, Geneva,sans-serif': 'Tahoma',
        "'Times New Roman', Times, serif": 'Times New Roman',
        'Verdana, Geneva, sans-serif': 'Verdana',
        "Wingdings, 'Zapf dingbats'": 'Wingdings',
      },
    },
  },
  cleanHTML: {
    denyTags: 'script,style',
  },
  disablePlugins: ['paste'],
};

interface IJoditTextEditorProps {
  onChanged: (value: string) => void;
  content: string;
  onBlur?: () => void;
  additionalButtons?: string[];
}

const JoditTextEditor: FC<IJoditTextEditorProps> = ({ content, onChanged, onBlur, additionalButtons }) => {
  const editor = useRef(null);
  const [inputValue, setInputValue] = useState(content);

  useEffect(() => {
    setInputValue(content);
  }, [content]);

  if (additionalButtons?.length) {
    config.buttons = [...buttons, ...additionalButtons];
  } else {
    config.buttons = [...buttons];
  }

  return (
    <div key="reactEditor">
      <JoditEditor
        ref={editor}
        value={inputValue}
        config={config}
        onBlur={() => onBlur?.()} // preferred to use only this option to update the content for performance reasons
        onChange={(newContent) => onChanged(newContent)}
      />
    </div>
  );
};

export default observer(JoditTextEditor);
