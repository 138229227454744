import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Styles from '../../../../styles/components/settings/systemAdminSettings/systemSettings/GeneralSettings.module.scss';
import { Toggle } from '@aurecon-creative-technologies/styleguide';
import TextEditor from '../../../shared/TextEditor';
import PrimaryButton from '../../../shared/PrimaryButton';

interface ISplashPageSettingsProps {
  defaultValue: boolean;
  splashPageContent: string;
  saveDisabled: boolean;
  onSettingsChanged: (value: boolean) => void;
  onSplashPageContentChanged: (value: string) => void;
  onSaveClick: () => void;
}
const SplashPageSettings: FC<ISplashPageSettingsProps> = (props) => {
  const { defaultValue, splashPageContent, saveDisabled, onSettingsChanged, onSplashPageContentChanged, onSaveClick } =
    props;
  const [setting, setSetting] = useState<boolean>(defaultValue);

  const handleSettingChange = (value) => {
    onSettingsChanged(value);
    setSetting(value);
  };

  const additionalButtons = ['image', 'video'];

  return (
    <div className={Styles.settingsContainer}>
      <div className={Styles.headingWrapper}>
        <p className={Styles.headingLabel}>Splash Page</p>
        <PrimaryButton className={Styles.btnSave} onClick={onSaveClick} disabled={saveDisabled}>
          Save
        </PrimaryButton>
      </div>
      <div>
        <p>The page all users see when they logged into Tucana</p>
        <Toggle onLabel="Yes" offLabel="No" value={setting} onChange={handleSettingChange} />
      </div>
      <p className={Styles.subHeadingLabel}>Splash Page Content</p>
      <div>
        <p className={Styles.splashDescription}>Description</p>
        <TextEditor
          content={splashPageContent}
          onChanged={onSplashPageContentChanged}
          additionalButtons={additionalButtons}></TextEditor>
      </div>
    </div>
  );
};

export default observer(SplashPageSettings);
