import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { ITab, TabContent } from '@aurecon-creative-technologies/styleguide';
import { TabFilter } from './Types';
import TransmittalsStore from './TransmittalsStore';
import PageHeading from '../shared/PageHeading';
import PageTab from '../shared/PageTab';
import TransmittalTabContent from './TransmittalTabContent';
import NavBarSelectorStore from './navBarSelector/NavBarSelectorStore';
import { appInsightsTrackEvent, appInsightsTrackPageView } from '../../utils/appInsights';
import { AppInsightPageView } from '../../common/constants/AppInsightPageView';
import AppStore from '../../stores/AppStore';
import { AppInsightEvent } from '../../common/constants/AppInsightEvent';

const Transmittals: FC = () => {
  const selectedItem = NavBarSelectorStore.selectedItem;

  useEffect(() => {
    appInsightsTrackPageView(AppInsightPageView.TRANSMITTALS_LIST);
    appInsightsTrackEvent(getAppInsightEventName(TransmittalsStore.currentTab));

    return () => {
      TransmittalsStore.abortRequests();
    };
  }, []);

  useEffect(() => {
    TransmittalsStore.abortRequests();

    if ((AppStore.projectAdminister || AppStore.isSystemAdmin) && !AppStore.isProjectExternalUser) {
      TransmittalsStore.fetchAllDeliveryTeamTransmittals();
    }
    // Get select option on component did mount
    TransmittalsStore.fetchTransmittals();
    TransmittalsStore.loadTableColumns();
    // Reset selected users on unmount
    return () => {
      TransmittalsStore.resetTransmittalContent();
    };
  }, [selectedItem]);

  const labelCount = (prefix: string, currentTransmittalTabCount: number, totalCount: number) => {
    if (TransmittalsStore.isLoading) return `${prefix} (loading)`;

    if (!currentTransmittalTabCount || currentTransmittalTabCount === totalCount) return `${prefix} (${totalCount})`;

    return `${prefix} (${currentTransmittalTabCount} of ${totalCount})`;
  };

  const TransmittalsTab: ITab[] = [
    {
      id: TabFilter.MyNotifications,
      label: labelCount(
        'My Notifications',
        TransmittalsStore.currentTab === TabFilter.MyNotifications ? TransmittalsStore.pagingMetaData.totalCount : 0,
        TransmittalsStore.transmittalSummary.notificationToMe
      ),
    },
    {
      id: TabFilter.InitiatedByMe,
      label: labelCount(
        'Initiated by Me',
        TransmittalsStore.currentTab === TabFilter.InitiatedByMe ? TransmittalsStore.pagingMetaData.totalCount : 0,
        TransmittalsStore.transmittalSummary.initiatedByMe
      ),
    },
    {
      id: TabFilter.VisibleToMe,
      label: labelCount(
        'Visible to Me',
        TransmittalsStore.currentTab === TabFilter.VisibleToMe ? TransmittalsStore.pagingMetaData.totalCount : 0,
        TransmittalsStore.transmittalSummary.visibleToMe
      ),
    },
  ];

  const setTab = (id: TabFilter) => {
    appInsightsTrackEvent(getAppInsightEventName(id));
    TransmittalsStore.setCurrentTab(id);
  };

  const getAppInsightEventName = (id: TabFilter) => {
    let eventName = AppInsightEvent.TRANSMITTALS_MY_NOTIFICATIONS_TAB;
    switch (id) {
      case TabFilter.InitiatedByMe:
        eventName = AppInsightEvent.TRANSMITTALS_INITIATED_BY_ME_TAB;
        break;
      case TabFilter.VisibleToMe:
        eventName = AppInsightEvent.TRANSMITTALS_VISIBLE_TO_ME_TAB;
        break;
      case TabFilter.AllDeliveryTeam:
        eventName = AppInsightEvent.TRANSMITTALS_ALL_DELIVERY_TEAM_TAB;
        break;
    }
    return eventName;
  };

  const TabsList: ITab[] =
    (AppStore.projectAdminister || AppStore.isSystemAdmin) && !AppStore.isProjectExternalUser
      ? [
          ...TransmittalsTab,
          {
            id: TabFilter.AllDeliveryTeam,
            label: labelCount(
              'All Delivery Team',
              TransmittalsStore.currentTab === TabFilter.AllDeliveryTeam
                ? TransmittalsStore.pagingMetaData.totalCount
                : 0,
              TransmittalsStore.allDeliveryTeamTotalCount
            ),
          },
        ]
      : TransmittalsTab;
  return (
    <>
      <PageHeading>Transmittals</PageHeading>
      <PageTab defaultActiveTab={TransmittalsStore.currentTab} onSelectTab={setTab} tabs={TabsList}>
        {TabsList.map((tab) => (
          <TabContent key={tab.id} for={tab.id}>
            <TransmittalTabContent></TransmittalTabContent>
          </TabContent>
        ))}
      </PageTab>
    </>
  );
};

export default observer(Transmittals);
