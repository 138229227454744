import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { classNames, validateEmail } from '../../utils/miscUtils';
import logo from './../../assets/client-logo.svg';
import FilesNavBarSelector from '../files/navBarSelector/NavBarSelector';
import TasksNavBarSelector from '../tasks/navBarSelector/NavBarSelector';
import VisualisationNavBarSelector from '../visualisation/navBarSelector/NavBarSelector';
import TransmittalsNavBarSelector from '../transmittals/navBarSelector/NavBarSelector';
import { Pages } from '../../common/constants/Pages';
import { useLocation, useNavigate } from 'react-router';
import Icon from '../shared/Icon';
import DropDownMenu from '../shared/DropDownMenu';
import DropDownMenuButton from '../shared/DropDownMenuButton';
import Style from './styles/NavBar.module.scss';
import AppStore from '../../stores/AppStore';
import config from '../../config';
import { ClientNames } from '../../common/constants/ClientNames';
import { NavLink } from 'react-router-dom';
import FilesStore from '../files/FilesStore';
import { useAuth } from '../../authentication/hooks/useAuth';
import { Button } from '@aurecon-creative-technologies/styleguide';

interface INavBarProps {
  className?: string;
  onClick: () => void;
}

const NavBar: FC<INavBarProps> = ({ className, onClick }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { logout } = useAuth();

  const handleButtonClick = (url: string): void => {
    window.open(url, '_blank', 'noopener');
  };
  useEffect(() => {
    return () => {
      if (!location.pathname.includes(Pages.Files.Route)) FilesStore.abortController?.abort();
    };
  });

  const handleSupport = (): void => {
    if (validateEmail(AppStore.client!.supportUrl)) {
      window.open('mailto:' + AppStore.client!.supportUrl, '_self');
      return;
    }
    window.open(AppStore.client!.supportUrl, '_blank', 'noopener');
  };

  let selector: JSX.Element | null = null;
  let disable = true;
  switch (true) {
    case location.pathname.includes(Pages.Files.Route):
      if (location.pathname.includes(Pages.Files.Route)) disable = false;
      selector = <FilesNavBarSelector disable={disable} />;
      break;
    case location.pathname.includes(Pages.Tasks.Route):
      if (location.pathname === Pages.Tasks.Route) disable = false;
      selector = <TasksNavBarSelector disable={disable} />;
      break;
    case location.pathname.includes(Pages.Visualisation.Route):
      if (location.pathname === Pages.Visualisation.Route) disable = false;
      selector = <VisualisationNavBarSelector disable={disable} />;
      break;
    case location.pathname.includes(Pages.Transmittals.Route):
      if (location.pathname === Pages.Transmittals.Route || location.pathname === `${Pages.Transmittals.Route}/new`)
        disable = false;
      selector = <TransmittalsNavBarSelector disable={disable} />;
      break;
    case location.pathname.includes(Pages.Apps.Route):
      if (location.pathname.includes(Pages.Apps.Route)) disable = false;
      selector = <FilesNavBarSelector disable={disable} />;
      break;
  }

  return (
    <div className={classNames(Style.navbar, className)}>
      <button className={Style.hamburger} onClick={onClick}>
        <Icon name="menu" />
      </button>
      {AppStore.clientEnableSplashPage ? (
        <NavLink to={Pages.Apps.Route}>
          <img className={Style.logo} src={logo} alt="" />
        </NavLink>
      ) : (
        <img className={Style.logo} src={logo} alt="" />
      )}
      {!!AppStore.client?.programmes.length && <div className={Style.projectSelector}>{selector}</div>}
      <div className={Style.fill}></div>
      {config.clientName !== ClientNames.ALR_CLIENT_NAME && config.clientName !== ClientNames.AJM_CLIENT_NAME && (
        <DropDownMenu icon="help" iconClassName={Style.menuIcon} itemsContainerClassName={Style.menuItemsContainer}>
          <DropDownMenuButton
            disabled={AppStore.disableNavigation}
            onClick={() => handleButtonClick(AppStore.client!.faqUrl)}>
            FAQ
          </DropDownMenuButton>
          <DropDownMenuButton
            disabled={AppStore.disableNavigation}
            onClick={() => handleButtonClick(AppStore.client!.userGuideUrl)}>
            User Guide
          </DropDownMenuButton>
          <DropDownMenuButton disabled={AppStore.disableNavigation} onClick={() => handleSupport()}>
            Support
          </DropDownMenuButton>
        </DropDownMenu>
      )}
      {(AppStore.administratorProjects || AppStore.isSystemAdmin) && (
        <Button
          type="custom"
          cssClass={classNames(Style.menuIcon, Style.settingsIcon)}
          icon="settings"
          onClick={() => navigate(Pages.Settings.Route)}
          size="extra large"
        />
      )}
      <DropDownMenu
        icon="account_circle"
        iconClassName={Style.menuIcon}
        itemsContainerClassName={Style.menuItemsContainer}>
        <DropDownMenuButton disabled={AppStore.disableNavigation} onClick={() => logout()}>
          Logout
        </DropDownMenuButton>
      </DropDownMenu>
    </div>
  );
};

export default observer(NavBar);
