import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { classNames } from '../../utils/miscUtils';
import Style from './styles/PageHeading.module.scss';

export interface IPageHeadingProps {
  subPage?: boolean;
  cssClass?: string;
}

const PageHeading: FC<IPageHeadingProps> = ({ subPage, cssClass, children }) => {
  return (
    <h1 className={classNames([!subPage, Style.heading], [!!subPage, Style.subHeading], [!!cssClass, cssClass])}>
      {children}
    </h1>
  );
};

export default observer(PageHeading);
