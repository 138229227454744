import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { FormInput, Text, Grid, Tooltip, Button, useToast, ComboBox } from '@aurecon-creative-technologies/styleguide';
import Style from './styles/NewFileFromTemplate.module.scss';
import UploadStore from './UploadStore';
import { MetaDataFieldTypeEnum, MetadataFieldType } from '../../../common/enums/MetadataFieldType';
import SecondaryButton from '../../shared/SecondaryButton';
import FilesStore from '../FilesStore';
import { FileContainerState } from '../../../common/enums/FileContainerState';
import Icon from '../../shared/Icon';
import { ContentSelection } from '../ContentSelection';
import AppStore from '../../../stores/AppStore';
import NamingConventionFields from './NamingConventionFields';
import UpdateApimsMetaDataFields from './UpdateApimsMetaDataFields';

interface IFileMetadataFormProps {
  heading?: string;
  selectedFiles?: number[];
  allowEditNamingField?: boolean;
  onApplyFileData?: () => void;
}
const FileMetadataForm: FC<IFileMetadataFormProps> = (props) => {
  const { heading, selectedFiles, allowEditNamingField, onApplyFileData } = props;
  const [expand, setExpand] = useState(true);
  const { addToast } = useToast();

  useEffect(() => {
    UploadStore.clearMetadataFileNameError();
  }, []);

  const maxLength = (length) => {
    if (!length) return 100;
    return length;
  };
  const isAPIMS = AppStore.applyApims;
  const isEditMetadata = FilesStore.selectedSection === ContentSelection.EditMetadata;
  const showEditApimsControls =
    (AppStore.projectAdminister || AppStore.isSystemAdmin) &&
    FilesStore.fileContainerStateId === FileContainerState.Wip &&
    isEditMetadata &&
    isAPIMS;

  const totalSelectedFiles =
    selectedFiles?.length ??
    UploadStore.filteredFileContainers.filter((x) => x.isSelected === true).flatMap((f) => f.files).length;

  const clearFileData = () => {
    UploadStore.clearFileData();
  };

  const applyFileData = () => {
    UploadStore.applyMetadataToSelectedFiles();
    addToast({
      type: 'success',
      message: `Metadata for ${totalSelectedFiles} Files has been updated successfully.`,
      timeout: 5000,
    });
  };

  const showTextLimit = (field) => {
    return (
      UploadStore.fileDataValues.find((fv) => fv.fieldValueIndex === field.fieldValueIndex)?.value.length ===
        maxLength(field.dataType.maxLength) && <div className={Style.txtLimit}>0 characters left</div>
    );
  };

  const checkIsNamingConventionField = (field) => {
    if (
      FilesStore.fileContainerStateId === FileContainerState.Wip &&
      FilesStore.selectedSection !== ContentSelection.EditMetadata
    )
      return false;

    if (field?.dataType?.parentFieldValueIndex) {
      return (
        UploadStore.fileMetadata?.find((t) => t.fieldValueIndex === field.dataType.parentFieldValueIndex)
          ?.isNamingConventionField ?? false
      );
    }

    return field.isNamingConventionField;
  };

  const disabledMetadataField = (field): boolean => {
    return (
      (FilesStore.fileContainerStateId !== FileContainerState.Wip ||
        FilesStore.selectedSection === ContentSelection.EditMetadata) &&
      field.isNamingConventionField &&
      allowEditNamingField !== undefined &&
      allowEditNamingField === false
    );
  };

  const renderMetadataComboBox = (field, selectedValue: string | undefined = undefined) => {
    return (
      <ComboBox
        label={field.title}
        showIcon={false}
        required={field.dataType.required}
        selected={selectedValue}
        options={UploadStore.getDropdownValues(field).map((x) => {
          return {
            id: x.id,
            value: x.label,
          };
        })}
        onSelect={(item) => {
          UploadStore.setFileDataValues(field.fieldValueIndex, item?.id.toString());
        }}
        onClear={() => UploadStore.removeFileDataValues(field.fieldValueIndex)}
        placeholder="- Select an option-"
        disabled={disabledMetadataField(field)}
        cssClass={Style.comboBox}
      />
    );
  };

  const buildMetadataComboBox = (field) => {
    const selectedValue = UploadStore.getSelectedMetadataValueByIndex(field.fieldValueIndex);
    if (selectedValue) return renderMetadataComboBox(field, selectedValue);
    return renderMetadataComboBox(field);
  };

  const buildMetadataFieldDropdown = (field) => {
    return (
      <div>
        {UploadStore.showMetadataField(field) ? (
          buildMetadataComboBox(field)
        ) : (
          <Tooltip show={`No ${field.title} available.`} defaultUp={false}>
            <ComboBox
              placeholder="- Select an option-"
              label={field.title}
              showIcon={false}
              required={field.dataType.required}
              options={[]}
              disabled={true}
            />
          </Tooltip>
        )}
      </div>
    );
  };

  const tooltipForNamingConventionField = (field) => {
    return (
      checkIsNamingConventionField(field) && (
        <Tooltip
          show={
            allowEditNamingField
              ? 'Overwriting this data will generate a new filename'
              : 'Can’t overwrite data that will overwrite filename'
          }
          defaultUp
          cssClass={Style.metadataFieldTooltip}>
          <Icon className={Style.infoIcon} name="info" outlined />
        </Tooltip>
      )
    );
  };

  return (
    <div className={`${Style.fromWrapper} ${expand ? Style.expand : ''}`}>
      <div className={Style.cardHeader}>
        <Text type="h3">{`${heading ?? 'File Data'}`}</Text>
        {UploadStore.showMetadataWarning && <p>You must input all required metadata fields before uploading.</p>}
      </div>

      <div className={`${Style.createForm} ${expand ? Style.expandCreateForm : ''}`}>
        <div className={Style.formHeader}>
          {expand && <span className={Style.requireText}>* is a required field</span>}
          <span>
            <Button
              type="icon-square"
              icon={expand ? 'chevron_left' : 'chevron_right'}
              cssClass={`${expand ? Style.collapseBtn : Style.expandBtn}`}
              onClick={() => setExpand(!expand)}></Button>
          </span>
        </div>
        {expand && (
          <>
            <Grid row={true} md={12}>
              {FilesStore.fileContainerStateId !== FileContainerState.Wip && !isEditMetadata && (
                <NamingConventionFields />
              )}

              {showEditApimsControls && <UpdateApimsMetaDataFields />}

              {UploadStore.fileMetadata
                .filter((x) => x.metaDataTypeId === MetaDataFieldTypeEnum.TucanaMetaDataField)
                .map((field) => {
                  return (
                    <Grid item md={12} key={field.fieldValueIndex}>
                      <div className={Style.formControl}>
                        {tooltipForNamingConventionField(field)}
                        {field.dataType.fieldType === MetadataFieldType.UserText ? (
                          <>
                            <FormInput
                              label={field.title}
                              cssClass={Style.input}
                              required={field.dataType.required}
                              value={
                                UploadStore.fileDataValues.find((fv) => fv.fieldValueIndex === field.fieldValueIndex)
                                  ?.value
                              }
                              onChange={(value) => UploadStore.setFileDataValues(field.fieldValueIndex, value)}
                              multilineLimit={maxLength(field.dataType.maxLength)}
                              disabled={disabledMetadataField(field)}
                            />
                            {showTextLimit(field)}
                          </>
                        ) : (
                          <div className={Style.dropdown}>{buildMetadataFieldDropdown(field)}</div>
                        )}
                      </div>
                    </Grid>
                  );
                })}
            </Grid>
            <div className={Style.actionButtons}>
              <Button cssClass={Style.buttonLink} onClick={clearFileData} type="text" label="Reset File Data" />
              <SecondaryButton
                disabled={!totalSelectedFiles || UploadStore.isApplyFileDataDisabled()}
                className={Style.applyMetadataBtn}
                onClick={() => (onApplyFileData ? onApplyFileData() : applyFileData())}>
                Apply File Data
              </SecondaryButton>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default observer(FileMetadataForm);
