import React, { FC, MouseEvent } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/DropDownMenuButton.module.scss';
import { classNames } from '../../utils/miscUtils';

export interface IDropDownMenuButtonProps {
  className?: string;
  onClick?: (event?: MouseEvent<HTMLButtonElement>) => void;
  disabled?: boolean;
}

const DropDownMenuButton: FC<IDropDownMenuButtonProps> = ({ className, onClick, disabled, children }) => {
  return (
    <button className={classNames(Style.button, className)} onClick={onClick} disabled={disabled}>
      {children}
    </button>
  );
};

export default observer(DropDownMenuButton);
