import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/ActionBar.module.scss';
import { classNames } from '../../utils/miscUtils';

export interface IActionBarProps {
  className?: string;
  selectedState?: boolean;
  selectedStateClassName?: string;
}

const ActionBar: FC<IActionBarProps> = ({ className, selectedState = false, selectedStateClassName, children }) => {
  return (
    <div
      className={classNames(
        Style.actionBar,
        [selectedState, Style.actionBarSelected, selectedStateClassName],
        className
      )}>
      {children}
    </div>
  );
};

export default observer(ActionBar);
