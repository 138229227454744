import { FormInput, Loader } from '@aurecon-creative-technologies/styleguide';
import { observer } from 'mobx-react-lite';
import React, { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Dot from '../../shared/Dot';
import FlexFiller from '../../shared/FlexFiller';
import GoBackButton from '../../shared/GoBackButton';
import Icon from '../../shared/Icon';
import PageHeading from '../../shared/PageHeading';
import PageNotFound from '../../shared/PageNotFound';
import AddTransmittalMessageVisibilitySelector from './AddTransmittalMessageVisibilitySelector';
import AddTransmittalMessageNotifySelector from './AddTransmittalMessageNotifySelector';
import Styles from './styles/AddTransmittalMessage.module.scss';
import AddTransmittalMessageActions from './AddTransmittalMessageActions';
import AddTransmittalMessageStore from './AddTransmittalMessageStore';
import classNames from 'classnames';
import SeeMoreLink from '../../shared/SeeMoreLink';
import TransmittalDetailsStore from '../TransmittalDetailsStore';
import TransmittalNotifiersModal from '../TransmittalNotifiersModal';
import TransmittalVisibleModal from '../TransmittalVisibleModal';
import { formatDate } from '../../../utils/dateUtils';
import CreateTransmittalContentFile from '../../transmittals/CreateTransmittalContentFile';
import SupportingFilesUploadModal from '../../transmittals/supportingFilesUpload/SupportingFilesUploadModal';
import {
  IOpenPillProps,
  SupportFileMode,
  TransmittalFileType,
  TransmittalTeamChartType,
} from '../../transmittals/Types';
import CreateTransmittalFile from '../../transmittals/CreateTransmittalFile';
import TransmittalSupportingFiles from '../../transmittals/TransmittalSupportingFiles';
import AppStore from '../../../stores/AppStore';
import FilesStore from '../../files/FilesStore';
import LayoutStore from '../../layout/LayoutStore';
import ErrorModal from '../../shared/ErrorModal';
import SecondaryButton from '../../shared/SecondaryButton';
import TeamChart from '../../TeamChart/TeamChart';
import { ITeamChartResult } from '../../TeamChart/interface/TeamChart.interface';
import {
  IAppointingParty,
  IDeliveryTeam,
  IDistributionList,
  ITaskTeam,
} from '../../../api/authenticated/um/getProjectMembersAndTeams';
import { TeamChartTitle } from '../../../common/constants/TeamChartTitle';
import { appInsightsTrackEvent, getAppInsightsInstance } from '../../../utils/appInsights';
import { Pages } from '../../../common/constants/Pages';
import { AppInsightPageView } from '../../../common/constants/AppInsightPageView';
import TransmittalResponse from '../TransmittalResponse';
import TransmittalDetailsFilesModal from '../TransmittalDetailsFilesModal';
import ContentFilesUploadModal from '../../transmittals/ContentFileUpload/ContentFilesUploadModal';
import TextEditor from '../../shared/TextEditor';
import TransmittalUserListModal from '../../transmittals/TransmittalUserListModal';
import { AppInsightEvent } from '../../../common/constants/AppInsightEvent';

const AddTransmittalMessage: FC = () => {
  const [showTeamChart, setShowTeamChart] = useState(false);
  const [transmittalTeamChartType, setTransmittalTeamChartType] = useState<TransmittalTeamChartType>('');
  const [openDetailFilesModal, setOpenDetailFilesModal] = useState(false);
  const [openFileOfMessageId, setOpenFileOfMessageId] = useState<number | null>(null);
  const [openSelectPillModal, setOpenSelectPillModal] = useState(false);
  const [selectPillItem, setSelectPillItem] = useState<IOpenPillProps>();

  const params = useParams();
  const addTransmittalErrorCode = AddTransmittalMessageStore.errorCode;
  useEffect(() => {
    const init = async () => {
      await AddTransmittalMessageStore.init(params.transmittalTitle);
    };

    init();
    AddTransmittalMessageStore.getOptionUsers();

    const appInsights = getAppInsightsInstance();
    if (appInsights)
      appInsights.trackPageView({
        name: AppInsightPageView.TRANSMITTAL_RESPONSE,
        isLoggedIn: true,
        uri: `${Pages.Transmittals.Route}/${params?.transmittalTitle}/AddResponse`,
      });
  }, [params]);

  const transmittalMessageList = AddTransmittalMessageStore.getTransmittalMessages();

  const handleShowResponseFilesModal = (messageId: number) => {
    setOpenFileOfMessageId(messageId);
    setOpenDetailFilesModal(true);
  };

  const onCloseUploadFileModal = async (uploadSucceeded: boolean, errorMessage?: string) => {
    AddTransmittalMessageStore.toggleUploadFileModal(false, uploadSucceeded, errorMessage);
  };

  const handleShowTeamChart = () => {
    appInsightsTrackEvent(AppInsightEvent.TRANSMITTALS_TEAM_CHART);
    setShowTeamChart(true);
  };

  const onTeamChartUserSelected = (teamChartData: ITeamChartResult) => {
    if (transmittalTeamChartType === 'Notify') {
      AddTransmittalMessageStore.setNotifierUsersFromTeamChart(teamChartData);
    } else if (transmittalTeamChartType === 'Visible') {
      AddTransmittalMessageStore.setVisibilityUsersFromTeamChart(teamChartData);
    }
    setShowTeamChart(false);
    setTransmittalTeamChartType('');
  };
  const handleRemoveContentFile = async (id: number, filetype: TransmittalFileType) => {
    AddTransmittalMessageStore.removeContentFile(id, filetype);
    return AddTransmittalMessageStore.contentFiles;
  };
  const onCloseUserListModal = () => {
    setSelectPillItem(undefined);
    setOpenSelectPillModal(false);
  };
  const handleUserListModal = (item: IOpenPillProps) => {
    setSelectPillItem(item);
    setOpenSelectPillModal(true);
  };
  const notifierUsersSelected = AddTransmittalMessageStore.transmittalResponse
    ? AddTransmittalMessageStore.transmittalResponse.selectedNotifyUsers.map((user) => ({
        userId: user.id,
        userName: user.name,
        userEmail: user.email,
        taskTeamId: user.taskTeamId,
        taskTeamTitle: user.taskTeamTitle,
        taskTeamCode: user.taskTeamCode,
        deliveryTeamId: user.deliveryTeamId,
        deliveryTeamTitle: user.deliveryTeamTitle,
        isExternal: user.isExternal,
      }))
    : [];

  const notifierAppointingPartyUsersSelected = AddTransmittalMessageStore.transmittalResponse
    ? AddTransmittalMessageStore.transmittalResponse.selectedNotifyAppointingPartyUsers.map((user) => ({
        userId: user.id,
        userName: user.name,
        userEmail: user.email,
        appointingPartyId: user.appointingPartyId,
      }))
    : [];

  if (AddTransmittalMessageStore.isLoadingTransmittal) return <Loader />;
  if (
    !AddTransmittalMessageStore.transmittal ||
    !AddTransmittalMessageStore.transmittalResponse ||
    !AddTransmittalMessageStore.transmittal.canRespond
  )
    return <PageNotFound />;
  if (AddTransmittalMessageStore.isOpenFiles)
    return (
      <CreateTransmittalFile
        isOpenFiles={AddTransmittalMessageStore.isOpenFiles}
        setIsOpenFiles={(value) => AddTransmittalMessageStore.setIsOpenFiles(value)}
        onAddFilesToTransmittal={(selectedFiles) => {
          AddTransmittalMessageStore.setSelectedContentFiles(selectedFiles);
          AddTransmittalMessageStore.toggleContentUploadFileModal(true);
          AddTransmittalMessageStore.setIsOpenFiles(false);
          AppStore.setDisableNavigation(false);
          selectedFiles.length
            ? LayoutStore.displayToast('success', 'Files added to Content Files successfully.')
            : LayoutStore.displayToast(
                'error',
                'Files cannot be added to Content Files at the moment. Please try again later.'
              );
          FilesStore.cleanup();
        }}
        onCancelAddFilesToTransmittal={() => {
          AddTransmittalMessageStore.setIsOpenFiles(false);
          AppStore.setDisableNavigation(false);
          FilesStore.cleanup();
        }}
      />
    );
  return (
    <>
      <GoBackButton />
      <div className={Styles.heading}>
        <PageHeading>RE: {AddTransmittalMessageStore.transmittal.transmittalMessages[0].subject}</PageHeading>
      </div>
      <AddTransmittalMessageActions />
      <div className={Styles.subHeading}>
        <p>{AddTransmittalMessageStore.transmittal.title}</p>
        {AddTransmittalMessageStore.transmittal.flagged && <Icon className={Styles.flagged} name="flag" />}
        {AddTransmittalMessageStore.transmittal.overdue && (
          <>
            <FlexFiller />
            <div>
              <Dot className={Styles.overdue} />
              <small>Overdue</small>
            </div>
          </>
        )}
      </div>
      <div className={Styles.addingPanel}>
        <div className={Styles.panel}>
          <div className={Styles.row}>
            <div className={Styles.userSelectorLabel}>
              <div className={Styles.labelIconWrapper}>
                <Icon className={Styles.labelIcon} name="notifications" />
                <span>Notified</span>
              </div>
            </div>
            <div className={Styles.userSelector}>
              <AddTransmittalMessageNotifySelector
                getUsers={AddTransmittalMessageStore.getMatchedNotifiedUsers}
                openSelectPillModal={handleUserListModal}
                onSelectedUserUpdated={AddTransmittalMessageStore.onSelectedNotifiedUsersUpdated}
                isMultiUser={true}
                disabled={false}
                searchPlaceholder={'Enter user name'}
                selectedNotifyItems={{
                  users: notifierUsersSelected,
                  distributionList: AddTransmittalMessageStore.transmittalResponse
                    .notifyToDistributionList as IDistributionList[],
                  appointingPartyUsers: notifierAppointingPartyUsersSelected,
                }}
              />
            </div>
            <div className={Styles.seeMoreLinkSection}>
              <div className={Styles.seeMoreLinkBtnWrapper}>
                <SeeMoreLink
                  onClick={() => (TransmittalDetailsStore.showNotifierModal = true)}
                  customizedStyle={Styles.seeMoreLink}
                />
                ({AddTransmittalMessageStore.getNotifyUserCounts()})
              </div>
            </div>
            <div className={Styles.teamChartBtnWrapper}>
              <SecondaryButton
                className={Styles.teamChartBtn}
                onClick={() => {
                  handleShowTeamChart();
                  setTransmittalTeamChartType('Notify');
                }}>
                Team Chart
              </SecondaryButton>
            </div>
          </div>
          <div className={classNames(Styles.row, Styles.separator)}>
            <div className={Styles.userSelectorLabel}>
              <div className={Styles.labelIconWrapper}>
                <Icon className={Styles.labelIcon} name="visibility" />
                <span>Visible to</span>
              </div>
            </div>
            <div className={Styles.userSelector}>
              <AddTransmittalMessageVisibilitySelector
                getItems={AddTransmittalMessageStore.getMatchedVisibilityItems}
                openSelectPillModal={handleUserListModal}
                onSelectedItemUpdated={AddTransmittalMessageStore.onSelectedVisibilityItemsUpdated}
                isMultiUser={true}
                disabled={false}
                searchPlaceholder={'Enter user name'}
                selectedVisibleTos={{
                  users: AddTransmittalMessageStore.transmittalResponse.selectedVisibleUsers.map((user) => ({
                    userId: user.id,
                    userName: user.name,
                    userEmail: user.email,
                    taskTeamId: user.taskTeamId,
                    taskTeamTitle: user.taskTeamTitle,
                    taskTeamCode: user.taskTeamCode,
                    deliveryTeamId: user.deliveryTeamId,
                    deliveryTeamTitle: user.deliveryTeamTitle,
                    isExternal: user.isExternal,
                  })),
                  taskTeams:
                    (AddTransmittalMessageStore.transmittalResponse?.visibleToTaskTeams as ITaskTeam[]) ??
                    ([] as ITaskTeam[]),
                  appointingParties: AddTransmittalMessageStore.transmittalResponse
                    .visibleToAppointingParties as IAppointingParty[],
                  deliveryTeams: AddTransmittalMessageStore.transmittalResponse
                    .visibleToDeliveryTeams as IDeliveryTeam[],
                  distributionList: AddTransmittalMessageStore.transmittalResponse
                    .visibleToDistributionList as IDistributionList[],
                  appointingPartyUsers:
                    AddTransmittalMessageStore.transmittalResponse.selectedVisibleAppointingPartyUsers.map((user) => ({
                      userId: user.id,
                      userName: user.name,
                      userEmail: user.email,
                      appointingPartyId: user.appointingPartyId,
                    })),
                }}
              />
            </div>
            <div className={Styles.seeMoreLinkSection}>
              <div className={Styles.seeMoreLinkBtnWrapper}>
                <SeeMoreLink
                  onClick={() => (TransmittalDetailsStore.showVisibleUserModal = true)}
                  customizedStyle={Styles.seeMoreLink}
                />
                ({AddTransmittalMessageStore.getVisibleUserCounts()})
              </div>
            </div>
            <div className={Styles.teamChartBtnWrapper}>
              <SecondaryButton
                className={Styles.teamChartBtn}
                onClick={() => {
                  handleShowTeamChart();
                  setTransmittalTeamChartType('Visible');
                }}>
                Team Chart
              </SecondaryButton>
            </div>
          </div>
          <div className={Styles.rowDisplay}>
            <div className={Styles.label}>Type</div>
            <div className={Styles.displayValue}>{AddTransmittalMessageStore.transmittal.transmittalTypeTitle}</div>
            <div className={Styles.label}>Reason</div>
            <div className={Styles.displayValue}>{AddTransmittalMessageStore.transmittal.transmittalReasonTitle}</div>
          </div>
          <div className={classNames(Styles.rowDisplay, Styles.separator)}>
            <div className={Styles.label}>Initiated Date</div>
            <div className={Styles.displayValue}>{formatDate(AddTransmittalMessageStore.transmittal.createdDate)}</div>
            <div className={Styles.label}>Due Date</div>
            <div className={Styles.displayValue}>
              {AddTransmittalMessageStore.transmittal.dueDate
                ? formatDate(AddTransmittalMessageStore.transmittal.dueDate)
                : ''}
            </div>
          </div>
          <div className={Styles.row}>
            <div className={Styles.label}>
              Subject <span className={Styles.requiredSymbol}>*</span>
            </div>
            <FormInput
              cssClass={Styles.formInput}
              value={AddTransmittalMessageStore.transmittalResponse.subject}
              onChange={AddTransmittalMessageStore.onSubjectChange}
              required={true}
              error={AddTransmittalMessageStore.subjectErrorMessage}
            />
          </div>
          <div className={Styles.row}>
            <div className={Styles.label}>
              Message <span className={Styles.requiredSymbol}>*</span>
            </div>
            <TextEditor
              onChanged={AddTransmittalMessageStore.onMessageChange}
              content={AddTransmittalMessageStore.transmittalResponse.message}
            />
          </div>
          {!!AddTransmittalMessageStore.contentFiles.length && (
            <div className={Styles.row}>
              <CreateTransmittalContentFile
                contentFiles={AddTransmittalMessageStore.contentFiles}
                onRemoveContentFile={(id, filetype) => handleRemoveContentFile(id, filetype)}
              />
            </div>
          )}
          <div className={Styles.row}>
            <TransmittalSupportingFiles
              openPanelIds={AddTransmittalMessageStore.openPanelIds}
              onPanelToggle={AddTransmittalMessageStore.panelToggle}
              supportingFiles={AddTransmittalMessageStore.getUploadSupportingFiles}
            />
          </div>
        </div>
        {TransmittalDetailsStore.showNotifierModal && (
          <TransmittalNotifiersModal
            data={TransmittalDetailsStore.transmittalNotifications}
            closeModal={() => (TransmittalDetailsStore.showNotifierModal = false)}></TransmittalNotifiersModal>
        )}
        {TransmittalDetailsStore.showVisibleUserModal && (
          <TransmittalVisibleModal data={TransmittalDetailsStore.transmittalVisible}></TransmittalVisibleModal>
        )}
      </div>
      {AddTransmittalMessageStore.showContentUploadFileModal && (
        <ContentFilesUploadModal
          isRespond={true}
          selectedContentContainers={AddTransmittalMessageStore.selectedContentFiles}
          onAddContentFilesToTransmittal={(containerFileIds) => {
            AddTransmittalMessageStore.handleUploadContentFiles(containerFileIds);
            AddTransmittalMessageStore.contentFiles.length
              ? LayoutStore.displayToast(
                  'success',
                  `${
                    AddTransmittalMessageStore.contentFiles.flatMap((m) => m.containerFiles).length
                  } Files added to Transmittal successfully.`
                )
              : LayoutStore.displayToast(
                  'error',
                  `${
                    AddTransmittalMessageStore.contentFiles.flatMap((m) => m.containerFiles).length
                  } Files cannot be added to Transmittal at the moment. Please try again later.`
                );
            AddTransmittalMessageStore.toggleContentUploadFileModal(false);
            AddTransmittalMessageStore.clearTransmittalContentFile();
          }}
          onCancelAddSelectedContentFiles={() => {
            AddTransmittalMessageStore.toggleContentUploadFileModal(false);
            AddTransmittalMessageStore.clearTransmittalContentFile();
          }}></ContentFilesUploadModal>
      )}
      {AddTransmittalMessageStore.showUploadSupportFileModal && (
        <SupportingFilesUploadModal
          mode={SupportFileMode.CreateResponse}
          payload={AddTransmittalMessageStore.transmittalResponse}
          transmittalId={AddTransmittalMessageStore.transmittal.id}
          projectNumber={AddTransmittalMessageStore.transmittal.projectNumber}
          closeModal={(uploadSucceeded, errorMessage) =>
            onCloseUploadFileModal(uploadSucceeded, errorMessage)
          }></SupportingFilesUploadModal>
      )}
      {AddTransmittalMessageStore.showUploadSupportFileErrorModal && (
        <ErrorModal
          closeModal={() => AddTransmittalMessageStore.setShowUploadSupportFileErrorModal(false)}
          errorCode={addTransmittalErrorCode}
          errorMessage={AddTransmittalMessageStore.uploadSupportFileErrorMessage}
        />
      )}
      {showTeamChart && AddTransmittalMessageStore.projectNumber && (
        <TeamChart
          closeModal={() => setShowTeamChart(false)}
          projectNumber={AddTransmittalMessageStore.projectNumber}
          onSelect={onTeamChartUserSelected}
          headerTitle={TeamChartTitle.transmittalTitle}
          selectedUsers={AddTransmittalMessageStore.selectedUsersForTeamChart(transmittalTeamChartType)}
        />
      )}
      {transmittalMessageList && (
        <TransmittalResponse responses={transmittalMessageList} showResponseFilesModal={handleShowResponseFilesModal} />
      )}
      {openDetailFilesModal && openFileOfMessageId && (
        <TransmittalDetailsFilesModal
          onClose={() => {
            setOpenDetailFilesModal(false);
            setOpenFileOfMessageId(null);
          }}
          messageId={openFileOfMessageId}
        />
      )}
      {openSelectPillModal && selectPillItem && (
        <TransmittalUserListModal
          item={selectPillItem}
          projectMembersAndTeams={AddTransmittalMessageStore.projectMembersAndTeams}
          closeModal={onCloseUserListModal}
        />
      )}
    </>
  );
};

export default observer(AddTransmittalMessage);
