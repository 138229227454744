import React, { FC, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { TabContent } from '@aurecon-creative-technologies/styleguide';
import { TabStore } from './TabStore';
import TabContainer from './TabContainer';
import { TabFilter } from './Types';
import TasksStore from './TasksStore';
import PageHeading from '../shared/PageHeading';
import PageTab from '../shared/PageTab';
import { appInsightsTrackEvent, appInsightsTrackPageView } from '../../utils/appInsights';
import { AppInsightPageView } from '../../common/constants/AppInsightPageView';
import { AppInsightEvent } from '../../common/constants/AppInsightEvent';

const Tasks: FC = () => {
  useEffect(() => {
    appInsightsTrackPageView(AppInsightPageView.TASK_LIST);
    appInsightsTrackEvent(getAppInsightEventName(TasksStore.currentTab));

    return () => {
      TasksStore.awaitingMyActionStore.abortController?.abort();
      TasksStore.createdByMeStore.abortController?.abort();
      TasksStore.allPendingTasksStore.abortController?.abort();
      TasksStore.allTasksStore.abortController?.abort();
    };
  }, []);

  const labelCount = (prefix: string, store: TabStore) => {
    if (store.isLoadingTasks) return `${prefix} (loading)`;

    if (store.availableTaskCount === store.tasks.length) return `${prefix} (${store.tasks.length})`;

    return `${prefix} (${store.tasks.length} of ${store.availableTaskCount})`;
  };

  const setTab = (id: TabFilter) => {
    appInsightsTrackEvent(getAppInsightEventName(id));
    TasksStore.setCurrentTab(id);
  };

  const getAppInsightEventName = (id: TabFilter) => {
    let eventName = AppInsightEvent.TASK_AWAITING_MY_ACTION_TAB;
    switch (id) {
      case TabFilter.CreatedByMe:
        eventName = AppInsightEvent.TASK_INITIATED_BY_ME_TAB;
        break;
      case TabFilter.AllPendingTasks:
        eventName = AppInsightEvent.TASK_ALL_PENDING_TASKS_TAB;
        break;
      case TabFilter.AllTasks:
        eventName = AppInsightEvent.TASK_ALL_TASKS_TAB;
        break;
    }
    return eventName;
  };

  return (
    <>
      <PageHeading>Tasks</PageHeading>
      <PageTab
        defaultActiveTab={TasksStore.currentTab}
        onSelectTab={setTab}
        tabs={[
          { id: TabFilter.AwaitingMyAction, label: labelCount('Awaiting My Action', TasksStore.awaitingMyActionStore) },
          { id: TabFilter.CreatedByMe, label: labelCount('Initiated By Me', TasksStore.createdByMeStore) },
          { id: TabFilter.AllPendingTasks, label: labelCount('All Pending Tasks', TasksStore.allPendingTasksStore) },
          { id: TabFilter.AllTasks, label: labelCount('All Tasks', TasksStore.allTasksStore) },
        ]}>
        <TabContent for={TabFilter.AwaitingMyAction}>
          <TabContainer store={TasksStore.awaitingMyActionStore} />
        </TabContent>
        <TabContent for={TabFilter.CreatedByMe}>
          <TabContainer store={TasksStore.createdByMeStore} />
        </TabContent>
        <TabContent for={TabFilter.AllPendingTasks}>
          <TabContainer store={TasksStore.allPendingTasksStore} />
        </TabContent>
        <TabContent for={TabFilter.AllTasks}>
          <TabContainer store={TasksStore.allTasksStore} />
        </TabContent>
      </PageTab>
    </>
  );
};

export default observer(Tasks);
