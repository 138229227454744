import React, { FC, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Modal from '../../shared/Modal';
import CloseButton from '../../shared/CloseButton';
import CentreOnPage from '../../shared/CentreOnPage';
import Overlay from '../../shared/Overlay';
import FileList from './FileList';

import Header from './Header';

import ActionButtons from './ActionButtons';
import Style from './styles/ReviewModal.module.scss';
import { useReviewStore } from './ReviewStoreContext';
import ErrorModal from '../../shared/ErrorModal';

import PageTab from '../../shared/PageTab';
import { ITab, TabContent } from '@aurecon-creative-technologies/styleguide/lib/components/TabMenu/TabMenu.component';
import { ReviewTabIds, ReviewTabLabels } from './ReviewTabTypeModel';
import AddSupportFiles from './AddSupportFiles';
import UserTabReviewModal from './UserTabReviewModal';

const ReviewModalContainer: FC = () => {
  const store = useReviewStore();
  const [selectedTabId, setSelectedTabId] = useState(ReviewTabIds.FILES);
  const tabContainerRef = useRef<HTMLDivElement>(null);

  const isCollaborate = store.state === 'Collaborate';
  const reviewTabs: ITab[] = [
    { id: ReviewTabIds.FILES, label: ReviewTabLabels.FILES },
    { id: ReviewTabIds.USER, label: ReviewTabLabels.USER },
    ...(!isCollaborate ? [{ id: ReviewTabIds.ADVANCE, label: ReviewTabLabels.ADVANCE }] : []),
  ];

  useEffect(() => {
    if (!tabContainerRef?.current) return;

    const tabItemClass = 'tab-list-item';
    const tabItemDisabled = 'tab-list-disabled';
    const tabActiveItemClass = 'tab-list-active';
    const customHoverClass = 'custom-hover';
    const tabItemElements = tabContainerRef.current.getElementsByClassName(tabItemClass);

    const mouseOverHandler = (tabItem) => {
      if (tabItem.className.includes(tabActiveItemClass) || tabItem.className.includes(tabItemDisabled)) return;

      const previousSibling = tabItem.previousSibling as HTMLDivElement;

      if (!previousSibling) return;
      if (previousSibling.className.includes(tabActiveItemClass))
        previousSibling.className = `${tabItemClass} ${tabActiveItemClass} ${customHoverClass}`;
    };

    const mouseLeaveHandler = (tabItem) => {
      if (tabItem.className.includes(tabActiveItemClass)) return;

      const previousSibling = tabItem.previousSibling as HTMLDivElement;

      if (!previousSibling) return;
      previousSibling.classList.remove(customHoverClass);
    };

    for (const tabItem of tabItemElements) {
      tabItem.addEventListener('mouseover', () => mouseOverHandler(tabItem));
      tabItem.addEventListener('mouseleave', () => mouseLeaveHandler(tabItem));
    }

    return () => {
      for (const tabItem of tabItemElements) {
        tabItem.removeEventListener('mouseover', () => mouseOverHandler(tabItem));
        tabItem.removeEventListener('mouseleave', () => mouseLeaveHandler(tabItem));
      }
    };
  }, [tabContainerRef]);

  const onSelectTab = (id: number) => {
    setSelectedTabId(id);
  };
  const onSelectNextTab = (currentTabId: number) => {
    switch (currentTabId) {
      case ReviewTabIds.FILES:
        setSelectedTabId(ReviewTabIds.USER);
        break;
      case ReviewTabIds.USER:
        setSelectedTabId(ReviewTabIds.ADVANCE);
        break;
      default:
        break;
    }
  };

  const renderTabContent = (tabId) => {
    switch (tabId) {
      case ReviewTabIds.FILES:
        return <FileList />;
      case ReviewTabIds.USER:
        return <UserTabReviewModal />;
      case ReviewTabIds.ADVANCE:
        return <AddSupportFiles />;
      default:
        break;
    }
  };

  return (
    <>
      {store.errorMessage === null ? (
        <CentreOnPage>
          <Overlay />
          <Modal className={Style.modal}>
            <div className={Style.modalBody}>
              <CloseButton onClick={() => !store.isSaving && store.closeModal()} disabled={store.isSaving} />
              <Header />
              <div className={Style.tabContainer} ref={tabContainerRef}>
                <PageTab
                  defaultActiveTab={selectedTabId}
                  tabs={reviewTabs}
                  onSelectTab={onSelectTab}
                  cssClass={Style.reviewModalTab}>
                  {reviewTabs.map((tab) => (
                    <TabContent key={tab.id} for={tab.id}>
                      {renderTabContent(tab.id)}
                    </TabContent>
                  ))}
                </PageTab>
              </div>
            </div>
            <div className={Style.modalActions}>
              <ActionButtons
                selectedTabId={selectedTabId}
                isCollaborate={isCollaborate}
                onSelectNextTab={() => {
                  onSelectNextTab(selectedTabId);
                }}
              />
            </div>
          </Modal>
        </CentreOnPage>
      ) : (
        <ErrorModal
          closeModal={() => store.closeModal()}
          errorCode={store.errorCode}
          errorMessage={store.errorMessage}
        />
      )}
    </>
  );
};

export default observer(ReviewModalContainer);
