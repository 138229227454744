import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import { classNames } from '../../utils/miscUtils';
import Style from './styles/ModalActions.module.scss';

interface IModalActionsProps {
  className?: string;
}

const ModalActions: FC<IModalActionsProps> = ({ className, children }) => {
  return <div className={classNames(Style.modalActions, className)}>{children}</div>;
};

export default observer(ModalActions);
