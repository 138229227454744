import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { TableCell } from '@aurecon-creative-technologies/styleguide';
import DropDownMenu from './DropDownMenu';
import Style from './styles/DropDownMenuForTableCell.module.scss';
import classNames from 'classnames';
export interface IDropDownMenuForTableCellProps {
  showAbove?: boolean;
  className?: string;
}

const DropDownMenuForTableCell: FC<IDropDownMenuForTableCellProps> = ({ children, showAbove, className }) => {
  return (
    <TableCell cellClass={Style.tableCell}>
      <div className={classNames(Style.cell, className)}>
        <DropDownMenu
          icon="more_vert"
          iconClassName={Style.icon}
          itemsContainerClassName={Style.itemsContainer}
          showAbove={showAbove}>
          {children}
        </DropDownMenu>
      </div>
    </TableCell>
  );
};

export default observer(DropDownMenuForTableCell);
