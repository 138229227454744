import React, { FC, useEffect, useRef, useState } from 'react';
import { observer } from 'mobx-react-lite';
import ActionBar from '../../../shared/ActionBar';
import ActionBarSection from '../../../shared/ActionBarSection';
import Style from '../../../../styles/components/settings/systemAdminSettings/systemUsers/SystemUserList.module.scss';
import { ITab, TabContent } from '@aurecon-creative-technologies/styleguide';
import SystemUsersStore from '../../../../stores/settings/systemAdminSettings/systemUser/SystemUsersStore';
import TabContainer from './TabContainer';
import PageTab from '../../../shared/PageTab';
import { SystemUsersTabIds, SystemUsersTabLabels } from '../../../../common/enums/SystemUserTabs';
import { CSVLink } from 'react-csv';
import { ISystemUserCSV } from '../../../../api/authenticated/um/interfaces/user.interface';
import { ICSVData } from '../../../../common/interfaces/csvData';
import SecondaryButton from '../../../shared/SecondaryButton';
import SearchBar from '../../../shared/SearchBar';
import SystemUserImportModal from './systemUserUpload/SystemUserImportModal';
import { IValidationImportResult } from '../../../../models/importSystemUsers/validationImportSystemUsers';
import { importSystemUsers } from '../../../../api/authenticated/um/importSystemUser';
import { Pages } from '../../../../common/constants/Pages';
import { SettingTabIds } from '../../Types';
import AppStore from '../../../../stores/AppStore';

const SystemUserList: FC = () => {
  const [csvData, setCsvData] = useState<ICSVData<ISystemUserCSV> | null>(null);
  const [showUploadFileModal, setShowUploadFileModal] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
  const canInviteOrRemoveAzure = AppStore.client?.canInviteOrRemoveAzure;

  const SystemUsersTabs: ITab[] = [
    { id: SystemUsersTabIds.ACTIVE, label: SystemUsersTabLabels.ACTIVE },
    { id: SystemUsersTabIds.DEACTIVE, label: SystemUsersTabLabels.DEACTIVE },
  ];

  const onSelectTab = (id: number) => {
    SystemUsersStore.setSelectedTabId(id);
  };

  const onCloseUploadFileModal = (uploadSucceeded: boolean) => {
    setShowUploadFileModal(false);
    if (uploadSucceeded) {
      SystemUsersStore.loadSystemUsers();
    }
  };

  const fetchDataAsync = async () => {
    setClicked(false);
    if (csvData && !!SystemUsersStore.allSystemUsers.length) return;

    setLoading(true);
    await SystemUsersStore.getAllSystemUsers();
    const mappingItems = SystemUsersStore.allSystemUsers;
    const data = SystemUsersStore.mappingCSVData(mappingItems);
    setCsvData(data);
    setLoading(false);
  };

  useEffect(() => {
    if (csvData && !clicked) {
      csvLinkRef?.current?.link.click();
      setClicked(true);
    }
  }, [clicked, csvData]);

  const handleImportSystemUser = async (readyForImport: IValidationImportResult[]) => {
    let response: {
      isSuccess: boolean;
      importResults: {
        email: string;
        status: string;
        reason: string;
      }[];
    };
    try {
      response = await importSystemUsers({
        systemUsers: readyForImport.map((m) => {
          return {
            email: m.email,
            displayName: canInviteOrRemoveAzure ? m.displayName : '',
          };
        }),
      });

      if (response.isSuccess) await SystemUsersStore.loadSystemUsers();
    } catch (error) {
      response = {
        isSuccess: false,
        importResults: readyForImport.map((m) => {
          return {
            email: m.email,
            status: 'Failure',
            reason: 'Something went wrong',
          };
        }),
      };
    }
    return response;
  };

  return (
    <>
      <ActionBar className={Style.pageHeading}>
        <p className={Style.pageHeadingLabel}>User List</p>
      </ActionBar>

      {SystemUsersStore.selectedTabId === SystemUsersTabIds.ACTIVE && (
        <div className={Style.actionContainer}>
          <div className={Style.actionHeader}>
            <SecondaryButton
              className={Style.btnMenuUploadBtn}
              disabled={loading}
              onClick={() => {
                setShowUploadFileModal(true);
              }}>
              Import Users
            </SecondaryButton>
            <SecondaryButton className={Style.btnMenuUploadBtn} disabled={loading} onClick={fetchDataAsync}>
              Export to CSV
              {csvData && (
                <CSVLink
                  headers={csvData?.headers ?? []}
                  filename={csvData?.filename}
                  data={csvData?.data ?? []}
                  ref={csvLinkRef}
                />
              )}
            </SecondaryButton>
          </div>
          <ActionBar className={Style.pageHeading}>
            <ActionBarSection>
              <SearchBar
                searchValue={SystemUsersStore.searchText}
                cssClass={Style.searchUserBox}
                onSearch={(keyword) => {
                  SystemUsersStore.setSearchText(keyword);
                }}
                placeHolderText="Quick search..."
              />
            </ActionBarSection>
          </ActionBar>
        </div>
      )}
      {showUploadFileModal && (
        <SystemUserImportModal
          closeModal={onCloseUploadFileModal}
          showModal={showUploadFileModal}
          importSystemUserCallback={handleImportSystemUser}
          systemUserUrl={`${Pages.Settings.Route}?tabid=${SettingTabIds.SYSTEM_USERS}`}
        />
      )}
      <PageTab defaultActiveTab={SystemUsersStore.selectedTabId} tabs={SystemUsersTabs} onSelectTab={onSelectTab}>
        {SystemUsersTabs.map((tab) => (
          <TabContent key={tab.id} for={tab.id}>
            <TabContainer tabId={tab.id} />
          </TabContent>
        ))}
      </PageTab>
    </>
  );
};

export default observer(SystemUserList);
