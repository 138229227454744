import React, { FC, useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/SupportFilesUploadModal.module.scss';
import CentreOnPage from '../../shared/CentreOnPage';
import Overlay from '../../shared/Overlay';
import Modal from '../../shared/Modal';
import CloseButton from '../../shared/CloseButton';
import ModalActions from '../../shared/ModalActions';
import SecondaryButton from '../../shared/SecondaryButton';
import PrimaryButton from '../../shared/PrimaryButton';
import FileDropZone from '../../shared/FileDropZone';
import { Text } from '@aurecon-creative-technologies/styleguide';
import SupportingFilesUploadStore from './SupportingFilesUploadStore';
import LayoutStore from '../../layout/LayoutStore';
import SelectSupportFileList from '../../shared/SelectSupportFileList';
import ProgressBar from '../../shared/ProgressBar';
import ErrorModal from '../../shared/ErrorModal';

interface ISupportingFilesUploadModalProps {
  projectNumber: string;
  taskId: number;
  closeModal: (uploadSucceeded: boolean, errorMessage?: string) => void;
}

const SupportingFilesUploadModal: FC<ISupportingFilesUploadModalProps> = (props) => {
  const { projectNumber, taskId, closeModal } = props;
  const [showErrorModal, setShowErrorModal] = useState(false);

  const closeTabEvent = (ev) => {
    if (!SupportingFilesUploadStore.selectedFiles.length) return;

    ev.preventDefault();
    ev.returnValue = 'You have unsaved changes. Are you sure you want to leave this page?';
    return ev.returnValue;
  };

  useEffect(() => {
    window.addEventListener('beforeunload', closeTabEvent);
    return () => {
      window.removeEventListener('beforeunload', closeTabEvent);
    };
  }, []);

  useEffect(() => {
    const init = async () => {
      await SupportingFilesUploadStore.initialUpload(projectNumber, taskId);
    };

    init();
  }, [projectNumber, taskId]);

  const handleCancelButtonClick = () => {
    closeModal?.(SupportingFilesUploadStore.showUploadSuccess, SupportingFilesUploadStore.errorMessage);
    SupportingFilesUploadStore.clear();
  };

  const onUploadButtonClick = async () => {
    await SupportingFilesUploadStore.handleUploadSupportingFiles();

    if (SupportingFilesUploadStore.filesFailedToUpload.length)
      SupportingFilesUploadStore.setErrorMessage(
        `${SupportingFilesUploadStore.filesFailedToUpload.length} file(s) cannot be uploaded at the moment. Please try again later.`
      );
    else {
      LayoutStore.displayToast(
        'success',
        `${SupportingFilesUploadStore.selectedFiles.length} has been uploaded successfully`
      );
    }
    handleCancelButtonClick();
  };

  const handleOnDropzoneChange = (acceptedFiles) => {
    SupportingFilesUploadStore.addFiles(acceptedFiles);
  };

  const errorMessage = () => {
    if (SupportingFilesUploadStore.isDuplicatedFiles)
      return `The selected file(s) already exist. Please choose another file and try again. ${SupportingFilesUploadStore.duplicatedFiles
        .map((f) => f.name)
        .join(', ')}`;
    if (SupportingFilesUploadStore.missingFileExtension)
      return `The selected file(s) missing File Extension. Please choose another files and try again. ${SupportingFilesUploadStore.missingExtensionFiles
        .map((f) => f.name)
        .join(', ')}`;

    if (SupportingFilesUploadStore.filesSizeExceededTheLimit)
      return 'Total file size exceed maximum limit. Maximum total file size is 1GB.';
    return '';
  };

  return (
    <>
      <CentreOnPage>
        <Overlay />
        <Modal className={Style.container}>
          <CloseButton onClick={handleCancelButtonClick} disabled={false} />
          <div>
            <Text type="h3">Add Supporting File(s)</Text>
            <div className={Style.uploadForm}>
              <FileDropZone
                label={
                  <div>
                    <p>
                      <b>Upload Supporting file(s)</b>
                    </p>
                    <p>Drag & drop or click to browse for files</p>
                    <p>(Max Upload: 1GB)</p>
                  </div>
                }
                multiple={true}
                loading={SupportingFilesUploadStore.isProcessing}
                onDropzoneChange={(acceptedFiles) => handleOnDropzoneChange(acceptedFiles)}
              />
              <SelectSupportFileList
                files={SupportingFilesUploadStore.uploadFiles}
                onRemoveFile={SupportingFilesUploadStore.removeFile}
              />
            </div>
          </div>
          <ModalActions>
            <SecondaryButton onClick={handleCancelButtonClick}>Cancel</SecondaryButton>
            <PrimaryButton
              disabled={
                !SupportingFilesUploadStore.selectedFiles.length ||
                SupportingFilesUploadStore.isCheckingDuplicated ||
                SupportingFilesUploadStore.openUploadError
              }
              onClick={() => onUploadButtonClick()}>
              Upload
            </PrimaryButton>
          </ModalActions>
        </Modal>
      </CentreOnPage>
      {showErrorModal && (
        <ErrorModal closeModal={() => setShowErrorModal(false)} errorCode={null} errorMessage={errorMessage()} />
      )}
      {SupportingFilesUploadStore.showProgressBar && (
        <ProgressBar
          completed={SupportingFilesUploadStore.percentageUploaded}
          uploadSuccess={SupportingFilesUploadStore.showUploadSuccess}
          uploadFailed={SupportingFilesUploadStore.showUploadFailed}
          listOfFailedFiles={SupportingFilesUploadStore.filesFailedToUpload || []}
          onClose={handleCancelButtonClick}
        />
      )}
    </>
  );
};

export default observer(SupportingFilesUploadModal);
