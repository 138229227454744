import React, { FC, useState } from 'react';
import { observer } from 'mobx-react-lite';
import Legend from '@arcgis/core/widgets/Legend';
import { Button } from '@aurecon-creative-technologies/styleguide/lib/components/Button/Button.component';
import { classNames } from '../../../utils/miscUtils';

import WidgetBase, { IWidgetBase } from './WidgetBase';

import Style from './styles/LegendWidget.module.scss';

const LegendWidget: FC = () => {
  const [expand, setExpand] = useState(false);

  const widget = WidgetBase(
    (properties: Partial<IWidgetBase>) =>
      new Legend({
        visible: true,
        container: properties.container,
        view: properties.view,
      }),
    classNames(Style.legendWidgetPanel, [expand, Style.legendContainer])
  );

  return (
    <div className={classNames(Style.legendWidgetContanier, [expand, Style.expand])}>
      {!expand && <Button type="icon-square" icon="dehaze" title="Legend" default onClick={() => setExpand(true)} />}
      {expand && (
        <div className={Style.legendHeader} id="legendHeader">
          <Button type="icon-square" icon="close" title="Close" onClick={() => setExpand(false)} />
        </div>
      )}
      {widget}
    </div>
  );
};

export default observer(LegendWidget);
