import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import UserCircleCollection from './UserCircleCollection';
import Style from './styles/ThreeUserCircles.module.scss';
import {
  IAppointingPartyCircle,
  ICircle,
  IDeliveryTeamCircle,
  ITaskTeamCircle,
  IUserCircle,
} from './Model/IThereCircleModel';
import AppointingPartyTag from './AppointingPartyTag';
import DeliveryTeamTag from './DeliveryTeamTag';
import { Pill } from '@aurecon-creative-technologies/styleguide';
import TaskTeamTag from './TaskTeamTag';
import { UserManagementTypeEnum } from '../../enums/UserManagementTypeEnum';
export interface IThreeUserCirclesProps {
  users: IUserCircle[];
  appointingParties?: IAppointingPartyCircle[];
  deliveryTeams?: IDeliveryTeamCircle[];
  taskTeams?: ITaskTeamCircle[];
}

const ThreeUserCircles: FC<IThreeUserCirclesProps> = ({
  users,
  appointingParties,
  deliveryTeams,
  taskTeams,
  children,
}) => {
  const circles: ICircle[] = [...users, ...(appointingParties || []), ...(deliveryTeams || []), ...(taskTeams || [])];
  const firstThreeUsers = circles.length > 3 ? circles.slice(0, 3) : circles;

  const appointingPartiesData = firstThreeUsers
    .filter((u) => u.type === UserManagementTypeEnum.AppointingParty)
    .map((ap) => ap as IAppointingPartyCircle);
  const deliveryTeamsData = firstThreeUsers
    .filter((u) => u.type === UserManagementTypeEnum.DeliveryTeam)
    .map((dt) => dt as IDeliveryTeamCircle);
  const taskTeamData = firstThreeUsers
    .filter((u) => u.type === UserManagementTypeEnum.TaskTeam)
    .map((tt) => tt as ITaskTeamCircle);
  const extra = circles.length - firstThreeUsers.length;
  return (
    <div className={Style.users}>
      <UserCircleCollection
        users={firstThreeUsers.filter((u) => u.type === UserManagementTypeEnum.User).map((u) => u as IUserCircle)}
      />
      {!!appointingPartiesData.length &&
        appointingPartiesData.map((ap) => (
          <Pill colour={1} key={ap.id} cssClass={Style.userPill}>
            <AppointingPartyTag
              appointingPartyCode={ap.appointingPartyCode}
              appointingPartyTitle={ap.appointingPartyTitle}
            />
          </Pill>
        ))}
      {!!deliveryTeamsData.length &&
        deliveryTeamsData.map((dt) => (
          <Pill colour={1} key={dt.id} cssClass={Style.userPill}>
            <DeliveryTeamTag deliveryTeamCode={dt.deliveryTeamCode} deliveryTeamTitle={dt.deliveryTeamTitle} />
          </Pill>
        ))}
      {!!taskTeamData.length &&
        taskTeamData.map((tt) => (
          <Pill colour={1} key={tt.id} cssClass={Style.userPill}>
            <TaskTeamTag
              taskTeamCode={tt.taskTeamCode}
              taskTeamTitle={tt.taskTeamTitle}
              userCount={tt.userCount}
              deliveryTeamTitle={tt.deliveryTeamTitle}
            />
          </Pill>
        ))}
      {!!extra && (
        <>
          <div className={Style.extra}>{`+${extra}`}</div>
          {children}
        </>
      )}
    </div>
  );
};

export default observer(ThreeUserCircles);
