import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';
import Style from './styles/ActionBarSection.module.scss';
import { classNames } from '../../utils/miscUtils';

export interface IActionBarSectionProps {
  className?: string;
}

const ActionBarSection: FC<IActionBarSectionProps> = ({ className, children }) => {
  return <div className={classNames(Style.actionBarSection, className)}>{children}</div>;
};

export default observer(ActionBarSection);
