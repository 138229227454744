import React, { FC, useState, useRef, useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { ContentSelection } from '../ContentSelection';
import FilesStore from '../FilesStore';
import Style from './styles/AddContent.module.scss';
import PrimaryButton from '../../shared/PrimaryButton';
import SecondaryButton from '../../shared/SecondaryButton';
import UploadStore from './UploadStore';
import { classNames } from '../../../utils/miscUtils';
import UploadUnsavedModal from './UploadUnsavedModal';
import UploadConfirmation from './UploadConfirmation';
import { FileContainerState } from '../../../common/enums/FileContainerState';
import AppStore from '../../../stores/AppStore';
import NavBarSelectorStore from '../navBarSelector/NavBarSelectorStore';
import { NavigationItemTypes } from '../../../common/models/ItemType';
import DropDownMenu from '../../shared/DropDownMenu';
import DropDownMenuButton from '../../shared/DropDownMenuButton';
import { ReactComponent as WordDocumentIcon } from '../../../assets/documentIcons/word.svg';
import { ReactComponent as PowerPointIcon } from '../../../assets/documentIcons/powerpoint.svg';
import { ReactComponent as ExcelIcon } from '../../../assets/documentIcons/excel.svg';
import AddUnbrandedFileModal from './AddUnbrandedFileModal';
import { FileExt } from '../../../common/constants/FileExt';
import { appInsightsTrackEvent } from '../../../utils/appInsights';
import { AppInsightEvent } from '../../../common/constants/AppInsightEvent';
import { Tooltip } from '@aurecon-creative-technologies/styleguide';
import Icon from '../../shared/Icon';
import { ICSVData } from '../../../common/interfaces/csvData';
import { CSVLink } from 'react-csv';
import { IExportFile } from './models/ExportFileModel';
import AddContentActionButtons from './AddContentActionButtons';

export const fileTypes = [
  {
    id: 1,
    name: 'File Upload',
  },
];

const UnbrandedDocumentPaths = [
  {
    type: FileExt.DOCX,
    path: './unbrandedDocuments/BlankDocument.docx',
  },
  {
    type: FileExt.XLSX,
    path: './unbrandedDocuments/BlankSpreadsheet.xlsx',
  },
  {
    type: FileExt.PPTX,
    path: './unbrandedDocuments/BlankPresentation.pptx',
  },
];

const AddContent: FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [showUploadUnsavedModal, setShowUploadUnsavedModal] = useState(false);
  const [showUploadConfirmationModal, setShowUploadConfirmationModal] = useState(false);
  const [showAddUnbrandedFileModal, setShowAddUnbrandedFileModal] = useState(false);
  const [unbrandedFileSeqNumber, setUnbrandedFileSeqNumber] = useState(0);
  const [selectedDocumentType, setSelectedDocumentType] = useState('');
  const [selectedDocumentPath, setSelectedDocumentPath] = useState<string | null>(null);
  const [selectedTemplateId, setSelectedTemplateId] = useState<number | null>(null);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const [exportData, setExportData] = useState<ICSVData<IExportFile> | null>(null);
  const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & { link: HTMLAnchorElement }>(null);
  const [clicked, setClicked] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    return () => {
      setUnbrandedFileSeqNumber(0);
    };
  }, []);

  const onAddButtonClick = (): void => setIsOpen(!isOpen);
  const onCancelButtonClick = async () => {
    appInsightsTrackEvent(AppInsightEvent.FILE_UPLOAD_CANCEL);

    await UploadStore.unlockFileContainers();
    setShowUploadUnsavedModal(false);
    FilesStore.setSelectedSection(FilesStore.getContentSelection(FilesStore.selectedTabId));
    UploadStore.clear();
    setUnbrandedFileSeqNumber(0);
  };

  const handleCancelButtonClick = () => {
    UploadStore.hasUnsavedChanges ? setShowUploadUnsavedModal(true) : onCancelButtonClick();
  };

  const onUploadButtonClick = async () => {
    setShowUploadConfirmationModal(false);
    await UploadStore.uploadFiles();
    setUnbrandedFileSeqNumber(0);
    setSelectedDocumentType('');
    setSelectedDocumentPath('');
  };

  const handleUploadButtonClick = () => {
    if (FilesStore.fileContainerStateId === FileContainerState.Wip) {
      onUploadButtonClick();
    } else {
      setShowUploadConfirmationModal(true);
    }
  };
  const closeTabEvent = (ev) => {
    ev.preventDefault();
    if (UploadStore.hasUnsavedChanges) {
      ev.returnValue = 'You have unsaved changes. Are you sure you want to leave this page?';
      UploadStore.unlockFileContainers();
    }

    return ev.returnValue;
  };

  useEffect(() => {
    window.addEventListener('popstate', closeTabEvent);
    window.addEventListener('beforeunload', closeTabEvent);
    return () => {
      window.removeEventListener('beforeunload', closeTabEvent);
      window.removeEventListener('popstate', closeTabEvent);
    };
  }, []);

  useEffect(() => {
    const handleClickEvent = (e) => {
      // If the active element exists and is clicked outside of
      if (dropdownRef.current !== null && !dropdownRef.current.contains(e.target)) {
        setIsOpen(!isOpen);
      }
    };

    // If the item is active (ie open) then listen for clicks
    if (isOpen) {
      window.addEventListener('click', handleClickEvent);
    }

    return () => {
      window.removeEventListener('click', handleClickEvent);
    };
  }, [isOpen]);

  const appInsightEventName = () => {
    if (FilesStore.fileContainerStateId === FileContainerState.Shared) return AppInsightEvent.FILE_ADD_SHARE;
    return FilesStore.fileContainerStateId === FileContainerState.Published
      ? AppInsightEvent.FILE_ADD_PUBLISHED
      : AppInsightEvent.FILE_ADD_WIP;
  };

  const onDropdownItemSelect = (selected: string) => {
    UploadStore.clear();
    UploadStore.getFileData();

    if (selected === 'File Upload') {
      appInsightsTrackEvent(appInsightEventName());
      FilesStore.setSelectedSection(ContentSelection.UploadFile);
    } else if (selected === 'WordFile') FilesStore.setSelectedSection(ContentSelection.AddTemplateDocument);
    else FilesStore.setSelectedSection(ContentSelection.AddBlankDocument, selected);

    setIsOpen(false);
  };

  const isToggleButton = !(
    FilesStore.selectedSection === ContentSelection.WIP ||
    FilesStore.selectedSection === ContentSelection.Published ||
    FilesStore.selectedSection === ContentSelection.Shared
  );

  const showAddBtn = () => {
    if (!NavBarSelectorStore.selectedItem || NavBarSelectorStore.selectedItem.type !== NavigationItemTypes.TaskTeam) {
      return false;
    }
    return (
      !NavBarSelectorStore.selectedItem.taskTeam.isTemporaryAccessible &&
      (AppStore.projectAdminister || FilesStore.fileContainerStateId === FileContainerState.Wip)
    );
  };

  const handleOnSetOriginalFileNameForTemplate = async (fileName: string, fileType: string, templateId: number) => {
    const originalFilename = `${fileName}.${fileType}`;
    await UploadStore.addUnbrandedFile(originalFilename, null, templateId);

    setShowAddUnbrandedFileModal(false);
  };

  const handleOnSetOriginalFileNameForUnbranded = async (fileName: string, fileType: string, filePath: string) => {
    const originalFilename = `${fileName}.${fileType}`;
    const response = await fetch(filePath);
    const blob = await response.blob();
    const file = new File([blob], originalFilename, {
      type: blob.type,
    });
    await UploadStore.addUnbrandedFile(originalFilename, file);

    setShowAddUnbrandedFileModal(false);
  };

  const handleOpenAddUnbrandedFileModal = (
    fileType: string,
    filePath: string | null = null,
    templateId: number | null = null
  ) => {
    setSelectedDocumentType(fileType);
    setSelectedDocumentPath(filePath);
    setSelectedTemplateId(templateId);
    setUnbrandedFileSeqNumber(unbrandedFileSeqNumber + 1);
    setShowAddUnbrandedFileModal(true);
  };

  const renderDocIcons = (type: string, docName?: string) => {
    switch (type) {
      case FileExt.DOCX:
        return (
          <>
            <WordDocumentIcon className={Style.dropdownButtonIcon} />{' '}
            <span>{!docName ? 'Word Document' : docName}</span>
          </>
        );

      case FileExt.XLSX:
        return (
          <>
            <ExcelIcon className={Style.dropdownButtonIcon} /> <span>{!docName ? 'Excel Spreadsheet' : docName}</span>
          </>
        );
      case FileExt.PPTX:
        return (
          <>
            <PowerPointIcon className={Style.dropdownButtonIcon} />
            <span>{!docName ? 'PowerPoint Presentation' : docName}</span>
          </>
        );
      default:
        return null;
    }
  };

  const renderUnbrandedDocumentDropdownOptions = () => {
    return UnbrandedDocumentPaths.map((m, i, arr) => {
      return (
        <DropDownMenuButton
          key={m.path}
          className={classNames(Style.dropdownOptionItem, [arr.length - 1 === i, Style.lastItem])}
          onClick={() => {
            handleOpenAddUnbrandedFileModal(m.type, m.path, null);
          }}>
          {renderDocIcons(m.type)}
        </DropDownMenuButton>
      );
    });
  };

  const renderTemplateDocumentDropdownOptions = () => {
    return UploadStore.uploadTemplates.map((m) => {
      return (
        <DropDownMenuButton
          key={m.path}
          className={Style.dropdownOptionItem}
          onClick={() => {
            handleOpenAddUnbrandedFileModal(m.extension, null, m.id);
          }}>
          {renderDocIcons(m.extension, m.templateFileName)}
        </DropDownMenuButton>
      );
    });
  };
  const fetchDataExport = async () => {
    setClicked(false);
    setLoading(true);
    const mappingItems = await FilesStore.downLoadAllFiles();
    const data = FilesStore.mappingExportData(mappingItems);
    setExportData(data);
    setLoading(false);
  };

  useEffect(() => {
    if (!loading && !clicked) {
      csvLinkRef?.current?.link.click();
      setClicked(true);
    }
  }, [clicked, loading]);
  return (
    <div className={Style.dropdown}>
      {isToggleButton ? (
        <>
          <PrimaryButton onClick={handleUploadButtonClick} disabled={UploadStore.isUploadDisabled()}>
            Upload
          </PrimaryButton>
          {showUploadConfirmationModal && (
            <UploadConfirmation
              message={
                UploadStore.selectedFileContainers.length > 1
                  ? 'I confirm that the files have been reviewed in the source system'
                  : 'I confirm that the file has been reviewed in the source system.'
              }
              closeModal={() => setShowUploadConfirmationModal(false)}
              redirect={onUploadButtonClick}></UploadConfirmation>
          )}
          <SecondaryButton onClick={handleCancelButtonClick}>Cancel</SecondaryButton>
          {showUploadUnsavedModal && (
            <UploadUnsavedModal
              closeModal={() => setShowUploadUnsavedModal(false)}
              redirect={onCancelButtonClick}></UploadUnsavedModal>
          )}
          <div className={Style.expandBtn}>
            <DropDownMenu
              label="Create"
              icon="expand_more"
              iconClassName={Style.expandBtnIcon}
              itemsContainerClassName={Style.itemsContainer}
              menuClassName={Style.btnMenu}>
              {renderUnbrandedDocumentDropdownOptions()}
              <div className={Style.divider}></div>
              {renderTemplateDocumentDropdownOptions()}
            </DropDownMenu>

            {showAddUnbrandedFileModal && (
              <AddUnbrandedFileModal
                selectedDocumentPath={selectedDocumentPath}
                templateId={selectedTemplateId}
                selectedType={selectedDocumentType}
                currentSeqNumber={unbrandedFileSeqNumber}
                onCancel={() => setShowAddUnbrandedFileModal(false)}
                onSet={(fileName: string, fileType: string, filePath: string, templateId: number | null) => {
                  if (templateId) {
                    handleOnSetOriginalFileNameForTemplate(fileName, fileType, templateId);
                    return;
                  }
                  handleOnSetOriginalFileNameForUnbranded(fileName, fileType, filePath);
                }}></AddUnbrandedFileModal>
            )}
          </div>
        </>
      ) : (
        <>
          {showAddBtn() && (
            <div>
              <PrimaryButton onClick={onAddButtonClick}>Add</PrimaryButton>
            </div>
          )}
          <Tooltip show={<div className={Style.tooltipText}>{'Export All'}</div>} defaultUp={true}>
            <SecondaryButton
              disabled={loading || FilesStore.isLoading}
              className={Style.btnExport}
              onClick={fetchDataExport}>
              <Icon name={'file_download'} />
              {!loading && exportData && (
                <CSVLink
                  headers={exportData?.headers ?? []}
                  filename={exportData?.filename}
                  data={exportData?.data ?? []}
                  ref={csvLinkRef}
                />
              )}
            </SecondaryButton>
          </Tooltip>
        </>
      )}
      <div>
        {isOpen && (
          <div ref={dropdownRef} className={Style.dropdownMenu}>
            {fileTypes.map((fileType) => (
              <AddContentActionButtons key={fileType.id} onClick={() => onDropdownItemSelect(fileType.name)}>
                {fileType.name}
              </AddContentActionButtons>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default observer(AddContent);
