import { computed, makeAutoObservable, runInAction } from 'mobx';
import { ContentSelection, FileContentTabIds, FileContainerStateNames } from './ContentSelection';
import { FileContainerState, FileContainerStateName } from '../../common/enums/FileContainerState';
import { getWipFileContainers } from '../../api/authenticated/cms/getWipFileContainers';
import { getNonWipFileContainers } from '../../api/authenticated/cms/getNonWipFileContainers';
import { FileContainerFilter } from '../../api/authenticated/cms/FileContainerFilterClass';
import { IFileContainer } from '../../api/authenticated/cms/FileContainerModel';
import { getProjectFileDownloadUrl } from '../../api/authenticated/cms/getProjectFileDownloadUrl';
import {
  getProjectMetadata,
  IFileMetadataFieldValue,
  IMetadataField,
} from '../../api/authenticated/cms/getProjectMetadata';
import NavBarSelectorStore from './navBarSelector/NavBarSelectorStore';
import FileInformationStore from './fileInformation/FileInformationStore';
import { getProjectFileDocumentViewerUrl } from '../../api/authenticated/cms/getProjectFileViewerUrl';
import AppStore from '../../stores/AppStore';
import { PagingArray } from '../../utils/pagingArray';
import { IPageMetaData, IPagedResponse } from '../../common/models/IPagedResponse';
import { DateFilterOperator, IDateRangeFilter, ITableColumn } from '../../common/interfaces/TableColumn';
import {
  ApimsMetaDataType,
  MetaDataFieldTypeEnum,
  MetadataFieldTitle,
  MetadataFieldType,
} from '../../common/enums/MetadataFieldType';
import { uniq, orderBy } from 'lodash';
import { deleteFile } from '../../api/authenticated/cms/deleteFile';
import { resendForgeFile } from '../../api/authenticated/cms/resendForgeFile';
import config from '../../config';
import { SortType, SortTypes } from '../../common/enums/SortType';
import { TaskTypeText } from '../../common/constants/TaskTypeText';
import { unlockFile } from '../../api/authenticated/cms/unlockFile';
import { AxiosError } from 'axios';
import { ITab } from '@aurecon-creative-technologies/styleguide';
import { NavigationItemTypes } from '../../common/models/ItemType';
import { getSuitabilities, ISuitability } from '../../api/authenticated/tasks/getAddData';
import { getSelectedItems } from '../../utils/getSelectedItems';
import { FromModuleEnum } from '../../enums/FromModuleEnum';
import { differenceInSeconds } from 'date-fns';
import { lockFile } from '../../api/authenticated/cms/lockFile';
import { IAdjustableTableColumn, IVisibilityColumn } from '../../common/interfaces/AdjustableTableColumn';
import {
  DeliveryTeam,
  getSharedAndPublishedDeliveryTeamAccess,
} from '../../api/authenticated/um/getSharedAndPublishedDeliveryTeamAccess';
import { appInsightsTrackEvent } from '../../utils/appInsights';
import { AppInsightEvent } from '../../common/constants/AppInsightEvent';
import { IExportFile, IExportHeaders } from './upload/models/ExportFileModel';
import { dateTimeFormat, FILE_CREATED_DATE } from '../../utils/dateUtils';
import { getFileSizeString } from '../../utils/miscUtils';

interface ILockedFileContainer {
  fileContainerId: number;
  fileContainerRevisionId: number;
}

export interface IDownloadFileContainerParam {
  fileContainers: IFileContainer[];
  projectNumber?: string;
  sharePointReleasedFileId?: number;
  transmittalId?: number;
  transmittalMessageId?: number;
  downloadAllContainerFile?: boolean;
  fileContainerStateId?: number;
}

const CheckBoxColumn = 'checkBoxColumn';
const ActionColumn = 'actionColumn';
const Pattern1Column = 'pattern1';
const Pattern2Column = 'pattern2';
const Pattern3Column = 'pattern3';

export const ActionColumns = [CheckBoxColumn, ActionColumn];
export const APIMS_COLUMNS = [Pattern1Column, Pattern2Column, Pattern3Column];
export class FilesStore {
  constructor() {
    makeAutoObservable(
      this,
      {
        selectedFiles: computed,
        canTaskTeamReviewOnSelectedFiles: computed,
        canShareReviewOnSelectedFiles: computed,
        canDeliveryTeamReviewOnSelectedFiles: computed,
        canPublishReviewOnSelectedFiles: computed,
        canCollaborateOnSelectedFiles: computed,
        canDownloadSelectedFiles: computed,
        canDeleteSelectedFiles: computed,
        isAnySelectedFileLocked: computed,
        selectedFilesSize: computed,
      },
      { autoBind: true }
    );
  }
  private _defaultColumnWidth = 100;
  private _fileTableColumnsStorageKey = 'files-table-columns';
  private _selectedFileMap: { [key: string]: IFileContainer } = {};
  private _lastSelectedIndexes: { [key: string]: number[] } = {};
  public _tableColumnsForForShowHide: IVisibilityColumn[] = [];
  public files: IFileContainer[] = [];
  public fileBusy: { [filedId: number]: boolean } = {};

  public selectedSection: ContentSelection = ContentSelection.WIP;
  public selectedFileType = '';
  public metadataFields: IMetadataField[] = [];
  public apimsMetadataFields: IMetadataField[] = [];
  public suitabilities: ISuitability[] = [];
  public selectedTabId = 1;
  public showSelectedFiles = false;
  public tableColumns: ITableColumn[] = [];

  public fileContainerStateId: FileContainerState = FileContainerState.Wip;
  public pageSize = config.defaultPageSize;
  public pageNumber = 1;
  public errorCode: number | null = null;
  public errorMessage: string | null = null;
  public fileContainerFilters: FileContainerFilter = new FileContainerFilter();
  public pagingMetaData: IPageMetaData = {
    pageNumber: 0,
    pageCount: 0,
  } as IPageMetaData;
  public isLockSupersedeFileError = false;
  public isLoading = false;
  public loadingLabel = 'Files loading';
  public isOpenFromUrl = false;
  public openSupersedeFile = false;
  public fromModule?: FromModuleEnum;
  public fileName?: string;
  public uploadSharedDeliveryTeams: DeliveryTeam[] = [];
  public uploadPublishedDeliveryTeams: DeliveryTeam[] = [];
  public abortController?: AbortController;

  public get tabs(): ITab[] {
    if (
      this.selectedSection === ContentSelection.UploadFile ||
      this.selectedSection === ContentSelection.EditMetadata
    ) {
      return [{ id: this.fileContainerStateId, label: FileContainerStateName[this.fileContainerStateId] }];
    }

    if (NavBarSelectorStore.selectedItem?.type === NavigationItemTypes.TaskTeam) {
      return [
        { id: FileContainerState.Wip, label: FileContainerStateName[FileContainerState.Wip] },
        { id: FileContainerState.Shared, label: FileContainerStateName[FileContainerState.Shared] },
        { id: FileContainerState.Published, label: FileContainerStateName[FileContainerState.Published] },
      ];
    }

    return [
      { id: FileContainerState.Shared, label: FileContainerStateName[FileContainerState.Shared] },
      { id: FileContainerState.Published, label: FileContainerStateName[FileContainerState.Published] },
    ];
  }

  public setQueryParametersFromUrl(fileName: string, stateId: string) {
    runInAction(() => {
      this.isOpenFromUrl = true;
      this.fileName = fileName ?? undefined;
      if (stateId === FileContainerStateNames.WIP) {
        this.selectedTabId = FileContentTabIds.WIP_TAB_ID;
        this.selectedSection = ContentSelection.WIP;
      } else if (stateId === FileContainerStateNames.SHARED) {
        this.selectedTabId = FileContentTabIds.SHARED_TAB_ID;
        this.selectedSection = ContentSelection.Shared;
      } else if (stateId === FileContainerStateNames.PUBLISHED) {
        this.selectedTabId = FileContentTabIds.PUBLISHED_TAB_ID;
        this.selectedSection = ContentSelection.Published;
      }
    });
  }

  public get getNavbarSelectedItem() {
    return NavBarSelectorStore.selectedItem;
  }

  public clearQueryParametersFromUrl() {
    runInAction(() => {
      this.isOpenFromUrl = false;
      this.fileName = undefined;
    });
  }
  public getTaskTeamFromTeamCode(taskTeamCode: string | undefined) {
    if (!taskTeamCode) return;
    const taskTeam = AppStore.client?.programmes
      .flatMap((m) => m.projects)
      .flatMap((m) => m.taskTeams)
      .find((m) => m.code === taskTeamCode);
    return taskTeam;
  }

  public getTaskTeamFromProjectAndTeamCode(taskTeamCode: string | undefined, projectNumber: string | undefined) {
    if (!taskTeamCode) return;

    return AppStore.client?.programmes
      .flatMap((a) => a.projects)
      .find((a) => a.projectNumber === projectNumber)
      ?.taskTeams.find((a) => a.code === taskTeamCode);
  }
  public setPagingMetaData(res: IPagedResponse<IFileContainer>) {
    const paginatedListObject = new PagingArray(this.pageSize, this.pageNumber, res.totalCount, res.pageCount);
    runInAction(() => {
      this.pagingMetaData = paginatedListObject.getMetaData();
    });
  }

  public async setCurrentPage(page) {
    if (page === this.pageNumber) return;
    this.pageNumber = page;
    this.files = [];
    this.fileContainerFilters.setPageCondition(this.pageNumber, this.pageSize);
    await this.loadFiles();
  }

  public setSelectedTabId(tabId: number) {
    runInAction(() => {
      this.selectedTabId = tabId;
      this.loadTableColumns();
    });
  }

  public setFromModule(fromModuleId: FromModuleEnum) {
    runInAction(() => {
      this.fromModule = fromModuleId;
      this.fileContainerFilters.setExcludeTemporaryAccess(fromModuleId === FromModuleEnum.TRANSMITTALS);
    });
  }

  public setDefaultSelectedTab() {
    runInAction(() => {
      if (!this.isOpenFromUrl) {
        this.selectedTabId = this.tabs[0].id;
        this.selectedSection =
          NavBarSelectorStore.selectedItem?.type === NavigationItemTypes.TaskTeam
            ? ContentSelection.WIP
            : ContentSelection.Shared;
      }
    });
  }

  public cleanup(isClearSelectedFile = true) {
    runInAction(() => {
      this.openSupersedeFile = false;
      this.pageNumber = 1;
      this.pagingMetaData = { pageNumber: 1, pageCount: 0 } as IPageMetaData;
      this.files = [];
      this.showSelectedFiles = false;
      this.suitabilities = [];
      if (this.abortController?.signal.aborted) this.isLoading = false;
      if (isClearSelectedFile) {
        this._selectedFileMap = {};
        this._lastSelectedIndexes = {};
      }
    });
  }

  public async setSelectedSection(contentSelection: ContentSelection, selectedFileType?: string, refreshData = true) {
    runInAction(() => {
      this.selectedSection = contentSelection;

      if (!refreshData) return;
      this.loadTableColumns();
      if (selectedFileType) this.selectedFileType = selectedFileType;
      this.fileContainerStateId = this.getFileContainerState(contentSelection);
      this.fileContainerFilters.setFileContainerState(this.fileContainerStateId);
      this.fileContainerFilters.setDefaultSort();
      this.abortController?.abort();
    });
    FileInformationStore.close();

    if (refreshData) {
      this.cleanup(this.fromModule !== FromModuleEnum.TRANSMITTALS);
      await this.getProjectSuitabilities();
      await this.loadFiles();
    }
  }

  public async getUploadDeliveryteamAccess() {
    runInAction(() => {
      this.uploadSharedDeliveryTeams = [];
      this.uploadPublishedDeliveryTeams = [];
    });
    if (
      !NavBarSelectorStore.selectedItem ||
      !AppStore.selectedDeliveryTeamId ||
      this.fileContainerStateId !== FileContainerState.Wip
    )
      return;
    const result = await getSharedAndPublishedDeliveryTeamAccess(
      NavBarSelectorStore.selectedItem?.project.projectNumber,
      AppStore.selectedDeliveryTeamId
    );

    runInAction(() => {
      this.uploadSharedDeliveryTeams = result.sharedDeliveryTeams;
      this.uploadPublishedDeliveryTeams = result.publishedDeliveryTeams;
    });
  }

  public async loadProjectMetadata() {
    if (!NavBarSelectorStore.selectedItem) return;
    runInAction(() => {
      this.fileContainerStateId = this.getFileContainerState(this.selectedSection);
      this.abortController = new AbortController();
    });

    const metadataFields = await getProjectMetadata(
      NavBarSelectorStore.selectedItem.project.projectNumber,
      this.abortController?.signal
    );

    runInAction(() => {
      this.tableColumns = [];
      this.metadataFields = metadataFields.filter(
        (x) => x.metaDataTypeId === MetaDataFieldTypeEnum.TucanaMetaDataField
      );
      this.apimsMetadataFields = metadataFields.filter(
        (x) => x.metaDataTypeId === MetaDataFieldTypeEnum.APIMSMetaDataField
      );
      this.loadTableColumns();
    });
    await this.loadFiles();
  }

  public async loadProjectMetadataByProjectNumber(projectNumber: string) {
    if (!projectNumber) return;
    const metadataFields = await getProjectMetadata(projectNumber);
    this.metadataFields = metadataFields.filter((x) => x.metaDataTypeId === MetaDataFieldTypeEnum.TucanaMetaDataField);

    runInAction(() => {
      this.metadataFields = metadataFields;
    });
  }

  public setDefaultFilter() {
    if (!NavBarSelectorStore.selectedItem) return;
    const fileContainerStateId = this.getFileContainerState(this.selectedSection);
    const taskTeamId =
      NavBarSelectorStore.selectedItem.type === NavigationItemTypes.TaskTeam
        ? NavBarSelectorStore.selectedItem.taskTeam.id
        : undefined;
    this.fileContainerFilters = new FileContainerFilter().setDefaultFilter(
      NavBarSelectorStore.selectedItem.project.projectNumber,
      fileContainerStateId,
      this.pageNumber,
      this.pageSize,
      taskTeamId
    );
    this.fileContainerFilters.setFileName(this.fileName);
    this.fileContainerFilters.setDefaultSort();
  }

  public applyFilter(column: string, filter?: string | IDateRangeFilter) {
    runInAction(async () => {
      this.files = [];
      this.pageNumber = 1;
      const parameter = this.getFilterFromTableColumns(column, filter);
      this.fileContainerFilters.setPageCondition(this.pageNumber, this.pageSize);
      this.fileContainerFilters.setExtraConditions(parameter);
      await this.loadFiles();
    });
  }

  private async getProjectSuitabilities() {
    if (NavBarSelectorStore.selectedItem) {
      this.abortController = new AbortController();
      const projectSuitabilities = await getSuitabilities(
        NavBarSelectorStore.selectedItem.project.projectNumber,
        this.fileContainerStateId,
        this.abortController?.signal
      );
      runInAction(() => {
        this.suitabilities = projectSuitabilities;
      });
    }
  }

  private getFilterFromTableColumns(column: string, filter?: string | IDateRangeFilter) {
    const parameter = {};

    this.tableColumns.forEach((c) => {
      if (c.valueField === column) {
        if (c.dateFilter) c.dateFilter = filter as IDateRangeFilter;
        if (c.textFilter) c.textFilter.filter = filter as string;
        if (c.listFilter) c.listFilter.filter = filter as string;
      }
      if (c.dateFilter) parameter[c.valueField] = c.dateFilter;
      if (c.textFilter) parameter[c.valueField] = c.textFilter.filter;
      if (c.listFilter) {
        let filterValue = c.listFilter.filter;
        if (c.valueField === 'taskTypeId') {
          filterValue = this.getTaskTypeValueFromText(filterValue);
        }
        if (c.valueField === 'suitabilityTitle') {
          filterValue = this.getSuitabilityCodeFromText(filterValue);
        }
        parameter[c.valueField] = filterValue;
      }
    });
    return parameter;
  }
  private getSuitabilityCodeFromText(filterValue?: string) {
    const selectedItem = this.suitabilities.find((x) => x.title === filterValue);
    return selectedItem?.code;
  }
  private getTaskTypeValueFromText(filterValue?: string) {
    let taskTypeValue = filterValue;
    Object.entries(TaskTypeText).forEach(([key, value]) => {
      if (value === filterValue) {
        taskTypeValue = key;
      }
    });
    return taskTypeValue;
  }

  public applySort(column?: string, direction?: SortType) {
    runInAction(async () => {
      this.pageNumber = 1;
      this.fileContainerFilters.setPageCondition(this.pageNumber, this.pageSize);
      this.fileContainerFilters.setSort(column, direction);
      this.tableColumns.forEach((c) => {
        if (c.valueField === column) {
          c.sort = direction;
        } else {
          c.sort = SortTypes.NONE;
        }
      });
      await this.loadFiles();
    });
  }

  private getFileContainerState(contentSelection: ContentSelection): FileContainerState {
    let fileContainerStateId = this.fileContainerStateId ?? FileContainerState.Wip;
    switch (contentSelection) {
      case ContentSelection.WIP:
        fileContainerStateId = FileContainerState.Wip;
        break;
      case ContentSelection.Shared:
        fileContainerStateId = FileContainerState.Shared;
        break;
      case ContentSelection.Published:
        fileContainerStateId = FileContainerState.Published;
        break;
      default:
        break;
    }

    return fileContainerStateId;
  }

  public getContentSelection(fileContainerStateId: FileContainerState): ContentSelection {
    if (fileContainerStateId === FileContainerState.Shared) return ContentSelection.Shared;

    if (fileContainerStateId === FileContainerState.Published) return ContentSelection.Published;

    return ContentSelection.WIP;
  }

  public closeLockSupersedeFileErrorModal() {
    runInAction(async () => {
      this.isLockSupersedeFileError = false;
      await this.loadFiles();
    });
  }

  public async loadFiles() {
    const allowRefreshFiles = [ContentSelection.WIP, ContentSelection.Shared, ContentSelection.Published];
    if (!AppStore.selectedProjectNumber || !allowRefreshFiles.some((item) => item === this.selectedSection)) return;
    runInAction(() => {
      this.isLoading = true;
      this.loadingLabel = 'Files loading';
      this.abortController = new AbortController();
    });

    try {
      const response = await (this.selectedSection === ContentSelection.WIP
        ? getWipFileContainers(this.fileContainerFilters, this.abortController?.signal)
        : getNonWipFileContainers(this.fileContainerFilters, this.abortController?.signal));
      this.setPagingMetaData(response);
      const files = response.data;
      const selectedFileMap = {};
      if (Object.keys(this._selectedFileMap).length) {
        Object.entries(this._selectedFileMap).forEach(([key, value]) => {
          const newFile = files.find((t) => this.getFileKey(t) === key) ?? value;
          selectedFileMap[key] = newFile;
        });
      }
      runInAction(() => {
        this.files = files;
        if (Object.keys(selectedFileMap).length) this._selectedFileMap = selectedFileMap;
      });
    } catch {
      runInAction(() => {
        this.isLoading = false;
      });
      this.cleanup();
    } finally {
      if (!this.abortController?.signal.aborted) {
        runInAction(() => {
          this.isLoading = false;
        });
      }
    }
  }

  public async downLoadAllFiles() {
    this.fileContainerFilters.setPageCondition(1, this.pagingMetaData.totalCount);
    const response = await (this.selectedSection === ContentSelection.WIP
      ? getWipFileContainers(this.fileContainerFilters, this.abortController?.signal)
      : getNonWipFileContainers(this.fileContainerFilters, this.abortController?.signal));

    this.fileContainerFilters.setPageCondition(this.pageNumber, this.pageSize);
    return response.data;
  }

  private initiateTableColumns = () => [
    {
      label: '',
      valueField: CheckBoxColumn,
      onCheckbox: (checked) => this.setSelectedForAllFiles(checked),
      checked: this.areAllFilesSelected(),
      width: 35,
    },
    {
      label: 'Filename',
      valueField: 'title',
      textFilter: { filter: this.fileName ?? this.getTableColumn('Filename')?.textFilter?.filter },
    },
    {
      label: 'Original Filename',
      valueField: 'originalFilename',
      textFilter: { filter: this.getTableColumn('Original Filename')?.textFilter?.filter },
    },
  ];

  private applyApims = (): ITableColumn[] => {
    const tableColumns: ITableColumn[] = [];
    if (!AppStore.applyApims) return tableColumns;
    tableColumns.push({
      label:
        this.apimsMetadataFields.find((x) => x.title === ApimsMetaDataType.Pattern1)?.description ??
        ApimsMetaDataType.Pattern1,
      valueField: Pattern1Column,
    });

    if (this.fileContainerStateId === FileContainerState.Wip || this.fileContainerStateId === FileContainerState.Shared)
      tableColumns.push({
        label:
          this.apimsMetadataFields.find((x) => x.title === ApimsMetaDataType.Pattern2)?.description ??
          ApimsMetaDataType.Pattern2,
        valueField: Pattern2Column,
      });

    if (this.fileContainerStateId === FileContainerState.Wip)
      tableColumns.push({
        label:
          this.apimsMetadataFields.find((x) => x.title === ApimsMetaDataType.Pattern3)?.description ??
          ApimsMetaDataType.Pattern3,
        valueField: Pattern3Column,
      });
    return tableColumns;
  };

  private renderDescMetadataColums = () => {
    const descMetadata = this.metadataFields.find((m) => MetadataFieldTitle.includes(m.title));
    if (!descMetadata) return [];
    return [
      {
        label: descMetadata.title,
        valueField: `fieldValue${descMetadata.fieldValueIndex}`,
        textFilter: { filter: this.getTableColumn(descMetadata.title)?.textFilter?.filter },
      } as ITableColumn,
    ];
  };

  public loadTableColumns() {
    let tableColumns: ITableColumn[] = this.initiateTableColumns();
    tableColumns = [...tableColumns, ...this.renderDescMetadataColums(), ...this.applyApims()];

    if (
      this.fileContainerStateId === FileContainerState.Wip ||
      this.fileContainerStateId === FileContainerState.Shared
    ) {
      const listWF: IFileMetadataFieldValue[] = [];
      Object.entries(TaskTypeText).forEach(([key, value]) => {
        listWF.push({
          code: key,
          title: value,
        } as IFileMetadataFieldValue);
      });
      let fieldValues: string[] = [];
      fieldValues = listWF.map((v) => v.title);
      fieldValues = uniq(fieldValues);
      fieldValues = orderBy(fieldValues);
      tableColumns.push({
        label: 'Workflow',
        valueField: 'taskTypeId',
        listFilter: {
          fieldValues,
        },
      });
    }

    if (
      this.fileContainerStateId === FileContainerState.Shared ||
      this.fileContainerStateId === FileContainerState.Published
    )
      tableColumns.push({
        label: 'Revision',
        valueField: 'formattedRevision',
        textFilter: { filter: this.getTableColumn('Revision')?.textFilter?.filter },
      });

    const metaDataColumns = this.buildFileMetadataColumns();
    tableColumns.push(...metaDataColumns);

    if (
      this.fileContainerStateId === FileContainerState.Shared ||
      this.fileContainerStateId === FileContainerState.Published
    ) {
      const suitabilityColumn = this.buildSuitabilityColumn();
      tableColumns.push(suitabilityColumn);
    }

    const modifiedDateFilter = this.getTableColumnByFieldId('modifiedDate')?.dateFilter;
    tableColumns.push({
      label: 'Modified Date',
      valueField: 'modifiedDate',
      sort: SortTypes.DESC,
      dateFilter: {
        startDate: modifiedDateFilter?.startDate ?? null,
        endDate: modifiedDateFilter?.endDate ?? null,
        operator: modifiedDateFilter?.operator ?? DateFilterOperator.EQUAL_TO,
      },
    });
    tableColumns.push({
      label: 'File Size',
      valueField: 'uploadedSize',
    });

    tableColumns.push({
      label: 'Number of files',
      valueField: 'containerFiles',
    });

    tableColumns.push({
      label: 'Task Team Originator',
      valueField: 'deliveryTeamTitle',
    });

    tableColumns.push({
      label: '',
      valueField: ActionColumn,
      width: 20,
    });

    const memorizedTableColumns = this.getAdjustableTableColumns();
    const fileTableColumns = memorizedTableColumns.length
      ? memorizedTableColumns
      : tableColumns.map((m) => {
          return {
            id: m.valueField,
            label: m.label,
            width: m.width ?? this._defaultColumnWidth,
            visible: true,
          };
        });

    localStorage.setItem(
      `${this._fileTableColumnsStorageKey}-${this.selectedSection}`,
      JSON.stringify(fileTableColumns)
    );

    runInAction(() => {
      const labelsToBeSorted = fileTableColumns.map((m) => m.id);
      this._tableColumnsForForShowHide = tableColumns
        .filter((f) => !ActionColumns.includes(f.valueField))
        .map((f) => ({ id: f.valueField, label: f.label }));
      const columns = [...tableColumns]
        .sort((a, b) => {
          const aIdx = labelsToBeSorted.indexOf(a.valueField);
          const bIdx = labelsToBeSorted.indexOf(b.valueField);

          if (aIdx < 0 || bIdx < 0) return 1;
          return labelsToBeSorted.indexOf(a.valueField) - labelsToBeSorted.indexOf(b.valueField);
        })
        .map((m) => {
          return {
            ...m,
            width: fileTableColumns.find((f) => f.id === m.valueField)?.width ?? this._defaultColumnWidth,
            minWidth: this._defaultColumnWidth,
          };
        });
      this.tableColumns = columns;
    });
  }

  public async lockMultipleSupersedeFileContainers(lockedFileContainers: ILockedFileContainer[]) {
    runInAction(() => {
      this.isLoading = true;
      this.loadingLabel = 'File locking';
    });

    const lockedFileIdContainers = await Promise.all(
      lockedFileContainers.map(async (f) => {
        const canLock = await this.lockSupersedeFile(f.fileContainerId, f.fileContainerRevisionId, false);
        return canLock ? f : null;
      })
    );

    runInAction(() => {
      this.isLoading = false;
    });
    return lockedFileIdContainers.filter((x: ILockedFileContainer | null) => x !== null);
  }

  public async unlockFile(fileContainerId: number, fileContainerRevisionId: number) {
    if (NavBarSelectorStore.selectedItem?.type !== NavigationItemTypes.TaskTeam) return;
    await unlockFile({
      fileContainerId,
      fileContainerRevisionId,
      taskTeamId: NavBarSelectorStore.selectedItem.taskTeam.id,
      fileContainerStateId: this.fileContainerStateId,
    });
  }

  public get getTaskTeamId() {
    if (NavBarSelectorStore.selectedItem?.type !== NavigationItemTypes.TaskTeam) return undefined;
    return NavBarSelectorStore.selectedItem.taskTeam.id;
  }

  public get filteredFiles(): IFileContainer[] {
    if (this.showSelectedFiles)
      return this.selectedFiles.filter((t) => t.fileContainerStateId === this.fileContainerStateId);
    return [...this.files];
  }
  public get getTableColumns(): ITableColumn[] {
    return [...this.tableColumns];
  }

  public get getTableColumnsForShowHide(): IVisibilityColumn[] {
    return this._tableColumnsForForShowHide;
  }

  private getTableColumn(label: string) {
    return this.tableColumns.find((tableColumn) => tableColumn.label === label);
  }

  private getTableColumnByFieldId(fieldId: string) {
    return this.tableColumns.find((tableColumn) => tableColumn.valueField === fieldId);
  }

  public getFileKey(file: IFileContainer) {
    return `${file.id}-${this.fileContainerStateId}-${file.revision ?? 0}`;
  }

  public handleSelectStart(file: IFileContainer, selected: boolean, shiftOn: boolean) {
    const currentSelectedItemIds = Object.entries(this._selectedFileMap)
      .filter(([, v]) => v.fileContainerStateId === this.fileContainerStateId)
      .map(([, v]) => this.getFileKey(v));
    const selectedItemIds = getSelectedItems(
      this.files.map((t) => ({ id: this.getFileKey(t) })),
      this.getFileKey(file),
      selected,
      shiftOn,
      currentSelectedItemIds,
      this._lastSelectedIndexes[this.selectedSection] || []
    );
    const selectedItems = this.files.filter((f) => selectedItemIds.some((s) => s === this.getFileKey(f)));
    const nonSelectedItems = Object.entries(this._selectedFileMap).filter(
      ([, v]) => !selectedItemIds.some((s) => this.getFileKey(v) === s)
    );

    for (const nonSelectedFile of nonSelectedItems) {
      const key = this.getFileKey(nonSelectedFile[1]);
      delete this._selectedFileMap[key];
    }

    for (const selectedFile of selectedItems) {
      this.setSelectedFile(selectedFile, true);
    }

    const selectedIndex = this.files.findIndex((t) => t.id === file.id);
    const lastSelectedIndexesOfSelectedSection = [
      ...(this._lastSelectedIndexes[this.selectedSection] ?? []),
      selectedIndex,
    ];
    this._lastSelectedIndexes[this.selectedSection] = lastSelectedIndexesOfSelectedSection;

    if (!this.selectedFiles.length && this.showSelectedFiles) this.toggleShowSelectedFiles();
  }

  public setSelectedFile(file: IFileContainer, selected: boolean) {
    const key = this.getFileKey(file);
    const fileContainerStateIdOfSelectedFile =
      this._selectedFileMap[key]?.fileContainerStateId ?? this.fileContainerStateId;
    runInAction(() => {
      if (selected) {
        this._selectedFileMap[key] = { ...file, fileContainerStateId: fileContainerStateIdOfSelectedFile };
      } else {
        delete this._selectedFileMap[key];
        if (!Object.keys(this._selectedFileMap).length) this.showSelectedFiles = false;
      }
      this.areAllFilesSelected();
    });
  }

  public resetSelectedFile() {
    if (this.selectedFiles.length)
      this.selectedFiles
        .map((file) => this.getFileKey(file))
        .forEach((key) => {
          delete this._selectedFileMap[key];
        });
  }

  public setShowLoading(showLoading: boolean, loadingMessage: string) {
    runInAction(() => {
      this.isLoading = showLoading;
      this.loadingLabel = loadingMessage;
    });
  }

  public setSelectedForAllFiles(selected: boolean) {
    this.files.forEach((file) => this.setSelectedFile(file, selected));
    this.areAllFilesSelected();
  }

  public hasSelectedFile(): boolean {
    return this.selectedFiles.length > 0;
  }

  public isFileSelected(file: IFileContainer): boolean {
    return !!this._selectedFileMap[this.getFileKey(file)];
  }

  public areAllFilesSelected(): boolean {
    let allSelected = this.files.length > 0;
    this.files.forEach((file) => (allSelected = allSelected && this.isFileSelected(file)));
    return allSelected;
  }

  public filesSelectedCount(): number {
    if (this.fromModule === FromModuleEnum.TRANSMITTALS)
      return this.selectedFiles.flatMap((x) => x.containerFiles).length;

    return this.selectedFiles.length;
  }

  public get selectedFiles() {
    const selected: IFileContainer[] = [];
    Object.entries(this._selectedFileMap).forEach(([key, value]) => {
      if (!selected.some((f) => this.getFileKey(f) === key)) {
        selected.push(value);
      }
    });
    return selected;
  }

  public get selectedFilesSize(): number {
    return this.selectedFiles.reduce((total, file) => total + file.uploadedSize, 0);
  }

  public get canTaskTeamReviewOnSelectedFiles(): boolean {
    if (this.isFilesLimitExceeded()) return false;
    return !!this.selectedFiles.length && !this.selectedFiles.find((file) => file.canTaskTeamReview === false);
  }

  public get canShareReviewOnSelectedFiles(): boolean {
    if (this.isFilesLimitExceeded()) return false;
    return !!this.selectedFiles.length && !this.selectedFiles.find((file) => file.canShareReview === false);
  }

  public get canDeliveryTeamReviewOnSelectedFiles(): boolean {
    if (this.isFilesLimitExceeded()) return false;
    return !!this.selectedFiles.length && !this.selectedFiles.find((file) => file.canDeliveryTeamReview === false);
  }

  public get canPublishReviewOnSelectedFiles(): boolean {
    if (this.isFilesLimitExceeded()) return false;
    return !!this.selectedFiles.length && !this.selectedFiles.find((file) => file.canPublishReview === false);
  }

  public get canCollaborateOnSelectedFiles(): boolean {
    return !!this.selectedFiles.length && this.selectedFiles.every((x) => x.canCollaborate);
  }

  public get hasSelectedReferenceFiles(): boolean {
    return this.selectedFiles.some((file) => file.isSuitabilityRefCode);
  }

  public get canDownloadSelectedFiles(): boolean {
    return !this.hasSelectedFile() || this.isFilesLimitExceeded();
  }

  public async downloadFile(param: IDownloadFileContainerParam) {
    param.fileContainers.forEach((file) => {
      if (this.fileBusy[file.id]) return;
      runInAction(() => {
        this.fileBusy[file.id] = true;
      });
    });

    const downloadFiles = param.fileContainers.map((file) => ({
      fileContainerId: file.id,
      containerFileId: param.downloadAllContainerFile ? null : file.containerFileId,
      fileRevisionId: file.fileRevisionId,
      releasedFileId: file.releasedFileId,
      sharePointReleasedFileId: param.sharePointReleasedFileId,
    }));

    this.clearError();
    try {
      const response = await getProjectFileDownloadUrl({
        projectNumber: param.projectNumber ?? NavBarSelectorStore.selectedItem!.project.projectNumber,
        transmittalId: param.transmittalId ?? null,
        transmittalMessageId: param.transmittalMessageId ?? null,
        downloadFiles,
        fileContainerStateId: param.fileContainerStateId ?? null,
      });
      if (response)
        response.forEach((res) => {
          if (res.url && !res.errorMessage) window.open(res.url);
        });
    } catch (err) {
      this.setError(err as AxiosError<string>);
    } finally {
      param.fileContainers.forEach((file) => {
        runInAction(() => {
          this.fileBusy[file.id] = false;
        });
      });
    }
  }

  public async downloadFiles(files: IFileContainer[], projectNumber: string) {
    if (!files?.length) return;
    return this.downloadFile({
      fileContainers: files.map((f) => ({
        ...f,
        releasedFileId: this.fileContainerStateId === FileContainerState.Wip ? null : f.releasedFileId,
      })),
      projectNumber: projectNumber,
    });
  }

  public async downloadSelectedFiles() {
    if (!this.selectedFiles) return;
    await this.downloadFile({
      fileContainers: this.selectedFiles.map((f) => ({
        ...f,
        releasedFileId: this.fileContainerStateId === FileContainerState.Wip ? null : f.releasedFileId,
      })),
      downloadAllContainerFile: true,
    });
  }

  public get canDeleteSelectedFiles(): boolean {
    if (NavBarSelectorStore.selectedItem?.type !== NavigationItemTypes.TaskTeam) return false;
    return (
      NavBarSelectorStore.selectedItem.taskTeam.canDelete &&
      this.selectedFiles.length > 0 &&
      !this.selectedFiles.find((file) => file.canDelete === false) &&
      !this.isFilesLimitExceeded()
    );
  }

  public async deleteFile(file: IFileContainer) {
    if (NavBarSelectorStore.selectedItem?.type !== NavigationItemTypes.TaskTeam) return;
    if (this.fileBusy[file.id]) return false;
    if (!NavBarSelectorStore.selectedItem.taskTeam.canDelete) return false;

    runInAction(() => {
      this.fileBusy[file.id] = true;
    });

    this.clearError();
    try {
      const response = await deleteFile({
        projectNumber: NavBarSelectorStore.selectedItem?.project?.projectNumber ?? AppStore.projectNumber ?? '',
        fileContainerId: file.id,
        fileContainerRevisionId: file.fileRevisionId,
        taskTeamId: NavBarSelectorStore.selectedItem.taskTeam.id,
      });
      this.fileBusy[file.id] = false;
      return !response?.message;
    } catch (err) {
      this.setError(err as AxiosError<string>);
    }
  }

  public async deleteSelectedFiles(): Promise<number> {
    if (!this.selectedFiles) return 0;
    this.clearError();

    try {
      await Promise.all(this.selectedFiles.map(async (contentFile) => await this.deleteFile(contentFile)));
      await this.loadFiles();
      return this.selectedFiles.length;
    } catch (err) {
      this.setError(err as AxiosError<string>);
      return 0;
    }
  }

  public async openFile(file: IFileContainer, projectNumber?: string, sharePointReleasedFileId?: number) {
    if (this.fileBusy[file.id]) return;

    appInsightsTrackEvent(AppInsightEvent.FILE_OPEN);
    runInAction(() => {
      this.fileBusy[file.id] = true;
    });

    this.clearError();

    try {
      const url = await getProjectFileDocumentViewerUrl({
        projectNumber: projectNumber ?? NavBarSelectorStore.selectedItem!.project.projectNumber,
        fileContainerId: file.id,
        fileContainerRevisionId: file.fileRevisionId,
        fileId: file.containerFileId,
        releasedFileContainerId: file.releasedFileId,
        transmittalId: null,
        transmittalMessageId: null,
        sharePointReleasedFileId: sharePointReleasedFileId ?? null,
      });
      if (url) window.open(url);
    } catch (err) {
      this.setError(err as AxiosError<string>);
    } finally {
      runInAction(() => {
        this.fileBusy[file.id] = false;
      });
    }
  }

  public setError(error: AxiosError<string>) {
    runInAction(() => {
      this.errorCode = error?.response?.status ?? null;
      this.errorMessage = error?.response?.data ?? null;
    });
  }

  public clearError() {
    runInAction(() => {
      this.errorCode = null;
      this.errorMessage = null;
    });
  }

  public async resendToForge(fileContainerRevisionId: number | null, containerFileId: number) {
    if (!fileContainerRevisionId || !containerFileId) return;
    runInAction(() => {
      this.errorMessage = null;
    });
    try {
      await resendForgeFile({
        projectNumber: NavBarSelectorStore.selectedItem!.project.projectNumber,
        fileContainerRevisionId,
        containerFileId,
      });
    } catch (err) {
      this.setError(err as AxiosError<string>);
    }
  }

  public isFilesLimitExceeded(): boolean {
    return this.filesSelectedCount() > (AppStore.client?.fileBatchLimit ?? 0);
  }

  public get isAnySelectedFileLocked(): boolean {
    let fileIsLocked = false;
    this.selectedFiles.forEach((file) => {
      if (file.isLocked === true) fileIsLocked = true;
    });
    return fileIsLocked;
  }

  public get isAnySelectedReachedLimitTimeToEditMetadata(): boolean {
    if (this.isIgnoreValidateLimitedTimeToEditFileMetadata()) return false;
    if (
      AppStore.client &&
      AppStore.client.applyLimitedTimeToEditFileMetadata &&
      AppStore.client.limitedTimeToEditFileMetadata
    ) {
      const now = new Date();
      const limitedTime = AppStore.client.limitedTimeToEditFileMetadata;
      return this.selectedFiles.some((x) => differenceInSeconds(now, x.createdDate) > limitedTime);
    }

    return false;
  }

  public get isTemporaryTaskTeam(): boolean {
    return (
      NavBarSelectorStore.selectedItem?.type === NavigationItemTypes.TaskTeam &&
      NavBarSelectorStore.selectedItem.taskTeam.isTemporaryAccessible
    );
  }

  public isFileReachedLimitedTimeToEditMetadata(file: IFileContainer): boolean {
    if (this.isIgnoreValidateLimitedTimeToEditFileMetadata()) return false;

    if (
      AppStore.client &&
      AppStore.client.applyLimitedTimeToEditFileMetadata &&
      AppStore.client.limitedTimeToEditFileMetadata
    ) {
      const now = new Date();
      const limitedTime = AppStore.client.limitedTimeToEditFileMetadata;
      return differenceInSeconds(now, file.createdDate) > limitedTime;
    }
    return false;
  }

  private isIgnoreValidateLimitedTimeToEditFileMetadata() {
    return AppStore.isSystemAdmin || AppStore.projectAdminister || !AppStore.client?.applyLimitedTimeToEditFileMetadata;
  }

  public toggleShowSelectedFiles() {
    this.showSelectedFiles = !this.showSelectedFiles;
  }

  public showFileInformation(file: IFileContainer, projectNumber: string | null) {
    let fileContainerStateId: FileContainerState | null = null;

    switch (this.selectedSection) {
      case ContentSelection.WIP:
        fileContainerStateId = FileContainerState.Wip;
        break;
      case ContentSelection.Shared:
        fileContainerStateId = FileContainerState.Shared;
        break;
      case ContentSelection.Published:
        fileContainerStateId = FileContainerState.Published;
        break;
    }

    if (!fileContainerStateId) return;

    FileInformationStore.init(file, fileContainerStateId, projectNumber, null, null);
  }

  public get isShowingFileInformation() {
    return !!FileInformationStore.file;
  }

  private buildFileMetadataColumns(): ITableColumn[] {
    const metaDataColumns: ITableColumn[] = [];
    this.metadataFields
      .filter((metaData) => !MetadataFieldTitle.includes(metaData.title))
      .forEach((f) => {
        const valueField = `fieldValue${f.fieldValueIndex}`;
        let fieldValues: string[] = [];
        if (f.dataType?.fieldType === MetadataFieldType.List) {
          fieldValues = f.dataType.fieldValues.map((v) => v.title);
          fieldValues = uniq(fieldValues);
          fieldValues = fieldValues.sort((a, b) => (a.toLocaleLowerCase() < b.toLocaleLowerCase() ? -1 : 1));

          metaDataColumns.push({
            label: f.title,
            valueField,
            listFilter: {
              fieldValues,
              filter: this.getTableColumn(f.title)?.listFilter?.filter,
            },
          } as ITableColumn);
        } else {
          metaDataColumns.push({
            label: f.title,
            valueField,
            textFilter: { filter: this.getTableColumn(f.title)?.textFilter?.filter },
          } as ITableColumn);
        }
      });
    return metaDataColumns;
  }

  private buildSuitabilityColumn(): ITableColumn {
    let suitabilityValues: string[] = [];
    suitabilityValues = this.suitabilities.map((v) => v.title);
    suitabilityValues = uniq(suitabilityValues);
    suitabilityValues = [...suitabilityValues].sort((a, b) => (a.toLocaleLowerCase() < b.toLocaleLowerCase() ? -1 : 1));

    return {
      label: 'Suitability',
      valueField: 'suitabilityTitle',
      listFilter: {
        fieldValues: suitabilityValues,
      },
    } as ITableColumn;
  }

  public applyFileFilterForTransmittals(
    contentSelection: ContentSelection,
    projectNumber?: string,
    taskTeamId?: number
  ) {
    if (!projectNumber) return;
    const fileContainerStateId = this.getFileContainerState(contentSelection);
    runInAction(() => {
      this.fileContainerFilters = new FileContainerFilter();
      this.fileContainerFilters.setDefaultFilter(
        projectNumber,
        fileContainerStateId,
        this.pageNumber,
        this.pageSize,
        taskTeamId
      );
    });
  }

  public setOpenSupersedeFile(open: boolean) {
    runInAction(() => {
      this.openSupersedeFile = open;
    });
  }

  public async ClearFilter() {
    runInAction(() => {
      this.tableColumns.forEach((c) => {
        c.dateFilter = undefined;
        c.listFilter = undefined;
        c.textFilter = undefined;
        c.sort = undefined;
      });
      this.cleanup();
      this.setDefaultFilter();
    });
    this.loadTableColumns();
    await this.loadFiles();
  }

  public setAdjustableTableColumns = (items: IAdjustableTableColumn[], reload?: boolean) => {
    runInAction(() => {
      localStorage.setItem(`${this._fileTableColumnsStorageKey}-${this.selectedSection}`, JSON.stringify(items));
      if (reload) {
        this.loadTableColumns();
      }
    });
  };

  public getAdjustableTableColumns = () => {
    const memorizedTableColumns = localStorage.getItem(`${this._fileTableColumnsStorageKey}-${this.selectedSection}`);
    return memorizedTableColumns ? (JSON.parse(memorizedTableColumns) as IAdjustableTableColumn[]) : [];
  };

  private async lockSupersedeFile(
    fileContainerId: number,
    fileContainerRevisionId: number,
    isSingleLockingOperation: boolean
  ) {
    if (NavBarSelectorStore.selectedItem?.type !== NavigationItemTypes.TaskTeam) return;

    if (isSingleLockingOperation) {
      runInAction(() => {
        this.isLoading = true;
        this.loadingLabel = 'File locking';
      });
    }

    let canlock: boolean;
    const response = await lockFile({
      fileContainerId,
      fileContainerRevisionId,
      taskTeamId: NavBarSelectorStore.selectedItem.taskTeam.id,
      fileContainerStateId: this.fileContainerStateId,
    });
    if (!response.message) {
      canlock = true;
    } else {
      canlock = false;
      runInAction(() => {
        this.errorMessage = response?.message ?? '';
        this.isLockSupersedeFileError = true;
      });
    }

    if (isSingleLockingOperation) {
      runInAction(() => {
        this.isLoading = false;
      });
    }

    return canlock;
  }
  private buildExportHeaderColumns(): IExportHeaders[] {
    const setHeaders: IExportHeaders[] = [
      { label: 'Filename', key: 'fileName' },
      { label: 'Original Filename', key: 'originalFilename' },
      { label: 'Modified date', key: 'modifiedDate' },
      { label: 'File size', key: 'uploadedSize' },
      { label: 'Number of files', key: 'numberOfFiles' },
      { label: 'Task team originator ', key: 'taskTeamOriginator' },
    ];
    this.metadataFields.forEach((f) => {
      setHeaders.push({
        label: f.title,
        key: `fieldValue${f.fieldValueIndex}`,
      });
    });
    this.apimsMetadataFields.forEach((f) => {
      if (f.title === ApimsMetaDataType.Pattern1) {
        setHeaders.push({
          label: f.description ?? ApimsMetaDataType.Pattern1,
          key: Pattern1Column,
        });
      }
      if (f.title === ApimsMetaDataType.Pattern2) {
        setHeaders.push({
          label: f.description ?? ApimsMetaDataType.Pattern2,
          key: Pattern2Column,
        });
      }
      if (f.title === ApimsMetaDataType.Pattern3) {
        setHeaders.push({
          label: f.description ?? ApimsMetaDataType.Pattern3,
          key: Pattern3Column,
        });
      }
    });
    return setHeaders;
  }
  public mappingExportData(mappingItems: IFileContainer[]) {
    const fetchCsvData: IExportFile[] = [];
    const projectNumber = NavBarSelectorStore.selectedItem?.project?.projectNumber ?? AppStore.projectNumber ?? '';

    mappingItems.forEach((file) => {
      const data = {
        fileName: file.title,
        originalFilename: file.originalFilename,
        modifiedDate: file.modifiedDate ? dateTimeFormat(file.modifiedDate) : '',
        taskTeamOriginator: `${file.deliveryTeamTitle} | ${file.taskTeamTitle}`,
        uploadedSize: getFileSizeString(file.uploadedSize),
        numberOfFiles: file.containerFiles?.length ?? 0,
        fieldValue1: file.fieldValue1,
        fieldValue2: file.fieldValue2,
        fieldValue3: file.fieldValue3,
        fieldValue4: file.fieldValue4,
        fieldValue5: file.fieldValue5,
        fieldValue6: file.fieldValue6,
        fieldValue7: file.fieldValue7,
        fieldValue8: file.fieldValue8,
        fieldValue9: file.fieldValue9,
        fieldValue10: file.fieldValue10,
        fieldValue11: file.fieldValue11,
        fieldValue12: file.fieldValue12,
        fieldValue13: file.fieldValue13,
        fieldValue14: file.fieldValue14,
        fieldValue15: file.fieldValue15,
        fieldValue16: file.fieldValue16,
        fieldValue17: file.fieldValue17,
        fieldValue18: file.fieldValue18,
        fieldValue19: file.fieldValue19,
        fieldValue20: file.fieldValue20,
        pattern1: file.pattern1,
        pattern2: file.pattern2,
        pattern3: file.pattern3,
      };

      if (file.containerFiles && file.containerFiles.length > 1) {
        file.containerFiles?.forEach((container) => {
          fetchCsvData.push({
            ...data,
            originalFilename: container.originalFilename,
          });
        });
      } else {
        fetchCsvData.push({
          ...data,
        });
      }
    });
    const exportHeaderColumns = this.buildExportHeaderColumns();

    const stateNames = {
      [FileContainerState.Wip]: 'WIP',
      [FileContainerState.Shared]: 'SHARED',
      [FileContainerState.Published]: 'PUBLISHED',
    };

    return {
      filename: `${projectNumber}${stateNames[this.fileContainerStateId]}-files${dateTimeFormat(
        new Date(),
        FILE_CREATED_DATE
      )}.csv`,
      headers: exportHeaderColumns,
      data: fetchCsvData,
    };
  }
}

export default new FilesStore();
