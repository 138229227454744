import { Button } from '@aurecon-creative-technologies/styleguide';
import { observer } from 'mobx-react-lite';
import React, { FC } from 'react';

export interface IPrimaryButtonProps {
  className?: string;
  disabled?: boolean;
  loading?: boolean;
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const PrimaryButton: FC<IPrimaryButtonProps> = ({ className, disabled, loading, onClick, children }) => {
  if (loading) return <Button label="Save" loading={true} />;
  return (
    <button className={className} disabled={disabled} onClick={onClick}>
      {children}
    </button>
  );
};

export default observer(PrimaryButton);
