import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Loader, Modal } from '@aurecon-creative-technologies/styleguide';
import Style from '../../../styles/components/settings/teamManagement/TeamManagement.module.scss';
import PrimaryButton from '../../shared/PrimaryButton';
import SecondaryButton from '../../shared/SecondaryButton';
import { TeamManagementAction } from '../../../common/constants/TeamManagementAction';

interface ICreateNewTeamModalProps {
  showModal: boolean;
  heading: string;
  message: string;
  disabledSave: boolean;
  onCancel: () => void;
  onSave: () => void;
  saving?: boolean;
  loading?: boolean;
  teamType: string;
}

const CreateNewTeamModal: FC<ICreateNewTeamModalProps> = ({
  showModal,
  heading,
  message,
  disabledSave,
  saving,
  loading,
  children,
  onCancel,
  onSave,
  teamType,
}) => {
  return (
    <Modal shouldOverlayClose={false} isShowing={showModal} onClose={onCancel} cssClass={Style.createNewTeamModal}>
      {loading ? (
        <Loader />
      ) : (
        <>
          <h1>{heading}</h1>
          <p>{message}</p>
          {children}
          <div className={Style.modalActions}>
            <SecondaryButton onClick={onCancel}>Cancel</SecondaryButton>
            {!saving && (
              <PrimaryButton disabled={disabledSave} onClick={onSave}>
                {teamType === TeamManagementAction.ADD_DISTRIBUTION_LIST ? 'Create' : 'Save'}
              </PrimaryButton>
            )}
            {saving && <Button label="Save" loading={saving} />}
          </div>
        </>
      )}
    </Modal>
  );
};

export default observer(CreateNewTeamModal);
