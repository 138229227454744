import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import Style from './styles/TextEditor.module.scss';
import JoditTextEditor from './JoditTextEditor';

export interface ITextEditorProps {
  onChanged: (value: string) => void;
  content: string;
  onBlur?: () => void;
  additionalButtons?: string[];
}

const TextEditor: FC<ITextEditorProps> = ({ content, onChanged, onBlur, additionalButtons }) => {
  const props = { content, onChanged, onBlur, additionalButtons };
  return (
    <div className={Style.textEditor}>
      <JoditTextEditor {...props} />
    </div>
  );
};

export default observer(TextEditor);
