import React, { FC } from 'react';
import { observer } from 'mobx-react-lite';
import { Text, Table, TableRow, TableCell, Pill, Icon } from '@aurecon-creative-technologies/styleguide';
import Style from './styles/SelectSupportFileList.module.scss';
import { getFileSizeString } from '../../utils/miscUtils';
import PrimaryIconButton from './PrimaryIconButton';
import { FileItem } from '../../common/interfaces/fileUpload';

interface IFileSelectedProps {
  files: FileItem[];
  onRemoveFile: (filename: string) => void;
}

const SelectSupportFileList: FC<IFileSelectedProps> = (props) => {
  const removeSelectedFile = (fileName): void => {
    props.onRemoveFile(fileName);
  };

  return (
    <div>
      {props.files.length > 0 && (
        <>
          <Text type="n1">Uploaded Files</Text>
          <Table
            breakpoint={10}
            cssClass={Style.filesTable}
            headers={[
              {
                label: 'File Name',
              },
              {
                label: '',
              },
              {
                label: 'File Size',
              },
              {
                label: 'Actions',
              },
            ]}
            hoverable>
            {props.files.map((f) => (
              <TableRow key={f.name} rowClass={f.duplicated ? `${Style.duplicated}` : ``}>
                <TableCell cellClass={Style.cellName}>{f.name}</TableCell>
                <TableCell>
                  {f.duplicated ? (
                    <div className={Style.validationCell}>
                      <Pill colour={9} size="small">
                        Duplicate
                      </Pill>
                      <Icon type="warning" className={Style.warningIcon} />
                    </div>
                  ) : (
                    <div className={Style.validationCell}>
                      <Pill colour={13} size="small">
                        Ready for Upload
                      </Pill>
                      <Icon type="check_circle" className={Style.checkIcon} />
                    </div>
                  )}
                </TableCell>
                <TableCell>{getFileSizeString(f.size)}</TableCell>
                <TableCell>
                  <PrimaryIconButton icon="delete" onClick={() => removeSelectedFile(f.name)} />
                </TableCell>
              </TableRow>
            ))}
          </Table>
        </>
      )}
    </div>
  );
};

export default observer(SelectSupportFileList);
